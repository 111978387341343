import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enLang from "./en/translations.json";
import nlLang from "./nl/translations.json";

const resources = {
    en: { translation: enLang },
    nl: { translation: nlLang },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        debug: false,
        fallbackLng: "nl",
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
