import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { AppBar, Toolbar, Typography, makeStyles, Theme } from "@material-ui/core";

import MainMenu from "shared/MainMenu";
import UserMenu from "shared/UserMenu";

import Logo from "assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { USER_PERMISSIONS } from "const";
import { useAppSelector } from "hooks";
import Cookies from "js-cookie";
import { AllowedCustomer } from "types";

const useStyles = makeStyles((theme: Theme) => ({
    offset: theme.mixins.toolbar,
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 80,
        width: "100%",
        boxShadow: "none",
    },
    logoWrap: {
        flex: "0 1 calc((100vw - 970px) / 2)",
        paddingLeft: theme.spacing(8),
        minWidth: 160,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    logo: {
        width: 100,
        height: 30,
    },
    menuWrap: {
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "100%",
        padding: 0,
    },
    link: {
        color: theme.palette.text.secondary,
        textTransform: "none",
        fontWeight: "normal",
        marginRight: theme.spacing(4),
        letterSpacing: "1.2px",
        lineHeight: "20px",
        textDecoration: "none",
        position: "relative",
    },
    active: {
        color: theme.palette.primary.main,
        "&::after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "2px",
            backgroundColor: theme.palette.primary.main,
            bottom: "-30px",
            left: 0,
        },
    },
    navWrap: {
        flex: `0 1 ${theme.breakpoints.values.lg}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

const Header: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    const permissions = useAppSelector((state) => state.auth.user.permissions);
    const currentUserName = useAppSelector((state) => state.auth.user.name);

    const customers = useAppSelector((state) => state.auth.user.allowedCustomers);
    const isImpersonating = useAppSelector((state) => state.auth.user.impersonating);

    const client = Cookies.get("client-nijburg");

    let currentCustomer = customers && customers?.find((customer: AllowedCustomer) => customer.CustomerId === client);

    const hasMultipleCustomer: boolean = !!(customers && customers.length > 1);

    const path = useMemo(() => location.pathname, [location.pathname]);

    if (path === "/login" || path === "/forgot-password" || path === "/reset-password") {
        return null;
    } else {
        return (
            <React.Fragment>
                <AppBar className={classes.header} position="fixed">
                    <Toolbar className={classes.menuWrap}>
                        <div className={classes.logoWrap}>
                            <div>
                                <img src={Logo} alt="Nijburg Logo" className={classes.logo} />
                                <Typography variant="body2" color="textPrimary">
                                    <b>KLANTEN</b> PORTAAL
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.navWrap}>
                            <MainMenu />
                            {isImpersonating && (
                                <Typography variant="body2" color="textSecondary">
                                    Impersonalisatie van{" "}
                                    <strong>
                                        {currentCustomer?.CustomerId} - {currentCustomer?.Name}
                                    </strong>
                                    <br/>
                                    Klant:{" "}
                                    <strong>
                                        {currentUserName}
                                    </strong>
                                </Typography>
                            )}
                            {hasMultipleCustomer && currentCustomer && (
                                <div>
                                    <Typography variant="body2" color="textSecondary">
                                        Vestiging{" "}
                                        <strong>
                                            {currentCustomer?.CustomerId} - {currentCustomer?.Name}
                                        </strong>
                                    </Typography>
                                </div>
                            )}
                            {permissions && permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders) && !isImpersonating && (
                                <div>
                                    <NavLink
                                        to="/return-policy"
                                        className={classes.link}
                                        activeClassName={classes.active}
                                        color="inherit"
                                        key={1}
                                    >
                                        {t("menu.returnPolicy")}
                                    </NavLink>
                                    <NavLink
                                        to="/contact"
                                        className={classes.link}
                                        activeClassName={classes.active}
                                        color="inherit"
                                        key={2}
                                    >
                                        {t("menu.contact")}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                        <UserMenu />
                    </Toolbar>
                </AppBar>
                <div className={classes.offset} />
            </React.Fragment>
        );
    }
};

export default Header;
