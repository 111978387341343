import { makeStyles, Typography, Theme } from "@material-ui/core";
import clsx from "clsx";

type StyledProps = {
    marginSize: "small" | "large";
};

const useStyles = makeStyles<Theme, StyledProps>((theme: Theme) => ({
    title: {
        display: "inline-flex",
        whiteSpace: "break-spaces",
    },
    line: (props) => ({
        marginBottom: theme.spacing(props.marginSize === "small" ? 2 : 4),
        "&::after": {
            content: "''",
            display: "block",
            height: 2,
            background: theme.palette.primary.main,
            marginTop: theme.spacing(1),
        },
    }),
}));

type Props = {
    text: string;
    line?: boolean;
    element: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    marginSize?: "small" | "large";
};

const RenderTitle: React.FC<Props> = ({ element, text, marginSize, line = false }) => {
    const classes = useStyles({ marginSize: marginSize || "large" });
    const textArray = text.split(" ");
    return (
        <>
            <Typography className={classes.title} variant={element}>
                {textArray &&
                    textArray.map((text: string, idx: number) => {
                        var newText = textArray.length > 1 && idx > 0 ? " " + text : text;
                        return (
                            <div className={clsx({ [classes.line]: line && idx === 0 })} key={idx}>
                                {newText}
                            </div>
                        );
                    })}
            </Typography>
        </>
    );
};

export default RenderTitle;
