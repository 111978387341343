import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { parse, stringify } from "query-string";

import { InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import { RETURN_ORDER_STATUS } from "const";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    wrap: {
        display: "flex",
    },
    label: {
        marginBottom: theme.spacing(2),
    },
    input: {
        height: "auto",
        padding: 10,
        border: "1px solid #CED5D8",
        borderRadius: theme.shape.borderRadius,

        "& .MuiInput-input": {
            padding: "0 8px",
            marginTop: 10,
        },

        "& .MuiSelect-root": {
            border: "1px solid black",
        },
    },
}));

type Props = {
    onChange: (value: typeof RETURN_ORDER_STATUS[keyof typeof RETURN_ORDER_STATUS]) => void;
    value: typeof RETURN_ORDER_STATUS[keyof typeof RETURN_ORDER_STATUS];
    id: string;
    label?: string;
};

const StatusFilter: React.FC<Props> = ({ onChange, value, id, label = "" }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const handleChange = (value: typeof RETURN_ORDER_STATUS[keyof typeof RETURN_ORDER_STATUS]) => {
        const val = value;
        const search = history.location.search.split("?")[1];
        let parsed = parse(search);

        if (val === "EMPTY") {
            delete parsed.status;
        } else {
            parsed.status = val;
            parsed.page = "1";
        }

        let newString = stringify(parsed);
        history.push(history.location.pathname + "?" + newString);

        onChange(val);
    };

    return (
        <div className={classes.root}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <Select
                id={id}
                value={value}
                classes={{
                    root: classes.input,
                }}
                onChange={(event) =>
                    handleChange(event.target.value as typeof RETURN_ORDER_STATUS[keyof typeof RETURN_ORDER_STATUS])
                }
                label={label}
            >
                {Object.keys(RETURN_ORDER_STATUS).map((type, idx) => {
                    // @ts-expect-error
                    const orderStatus = t(`orderStatus.${type}`);
                    const currentType = (RETURN_ORDER_STATUS as any)[type];

                    return (
                        <MenuItem key={idx} value={currentType}>
                            {orderStatus}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export default StatusFilter;
