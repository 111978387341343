import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}api/GraphQL`,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext();
    const status = context?.response?.status || null;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            if (message.includes("Insufficient permissions")) {
                window.location.href = "/login";
            }
        });
    }

    if (status && status !== null && status === 401) {
        window.location.href = "/login";
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const authLink = setContext((_, { headers }) => {
    let token = Cookies.get("jwt-nijburg");
    let client = Cookies.get("client-nijburg");

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            NijburgCustomerId: client || 0,
        },
    };
});

const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

export default client;
