import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Cookies from "js-cookie";

import { makeStyles, Theme, Paper, Typography, Box } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { AuthService } from "services";
import { USER_PERMISSIONS } from "const";

import { AuthAnimation } from "theme/Animations";
import { CustomInput, CustomButton } from "shared";
import LayoutWrap from "./LayoutWrap";
import Logo from "assets/images/logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "0 1 500px",
        position: "relative",
        alignSelf: "flex-start",
        zIndex: 10,
        marginTop: "5%",
        transform: "translateX(10vw)",
        minWidth: 500,
    },
    paper: {
        minHeight: 460,
        borderRadius: `0 ${theme.shape.borderRadius * 2}px 0 ${theme.shape.borderRadius * 2}px`,
        padding: theme.spacing(10),
        boxShadow: "0 15px 40px 0 rgba(87,86,86,0.15)",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(4),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));

const Login = () => {
    const { t } = useTranslation();

    const classes = useStyles();
    const auth = AuthService();
    const history = useHistory();
    const location = useLocation();
    const AnimationProps = useSpring(AuthAnimation);

    const [loading, setLoading] = useState<boolean>(false);
    const [formError, setFormError] = useState<boolean>(false);
    const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
    const [form, setForm] = useState({
        password: "",
        email: "",
    });

    const cookieRef = useRef(Cookies);

    useEffect(() => {
        if (location.search.includes("redirectPath")) {
            const path = location.search.split("=");
            setRedirectPath(path[1]);
            cookieRef.current.remove("jwt-nijburg", { path: "/" });
        }
    }, [location]);

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        setFormError(false);

        if (!form.password || !form.email) {
            setFormError(true);
            setLoading(false);
            return;
        }

        auth.userLogin(form.email, form.password).then((resp) => {
            setLoading(false);
            //@ts-ignore
            if (resp.success) {
                //@ts-ignore
                const permissions = resp.success.permissions;
                //@ts-ignore
                const hasMultipleCustomers = resp.success.allowedCustomers?.length > 1 ?? false;

                if (redirectPath) {
                    history.push(redirectPath);
                } else if (permissions.includes(USER_PERMISSIONS.Nijburg_CanImpersonate)) {
                    history.push("/impersonate");
                } else if (hasMultipleCustomers) {
                    history.push("/location-select");
                } else if (permissions) {
                    if (permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders)) {
                        history.push("/orders");
                    } else if (
                        permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) ||
                        permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics)
                    ) {
                        history.push("/return-orders");
                    } else if (permissions.includes(USER_PERMISSIONS.Nijburg_CanImpersonate)) {
                        history.push("/impersonate");
                    } else {
                        history.push("/");
                    }
                }
            } else {
                setFormError(true);
            }
        });
    };

    const handleInputChange = (value: string, id: string) => {
        setForm((form) => ({
            ...form,
            [id]: value,
        }));
    };

    return (
        <LayoutWrap>
            <animated.div className={classes.root} style={AnimationProps}>
                <Paper className={classes.paper}>
                    <Box mb={8}>
                        <img src={Logo} alt="Logo Nijburg Industry Group" />
                    </Box>
                    <Typography variant="h1">{t("auth.login")}</Typography>
                    <Typography variant="body1" color="textSecondary">
                        {t("auth.loginText")}
                    </Typography>
                    <form className={classes.form} onSubmit={(e) => handleSubmit(e)} noValidate>
                        <CustomInput
                            error={formError}
                            id="email"
                            value={form.email}
                            label={t("form.email")}
                            type="email"
                            onChange={handleInputChange}
                        />
                        <CustomInput
                            error={formError}
                            id="password"
                            value={form.password}
                            label={t("form.password")}
                            type="password"
                            onChange={handleInputChange}
                        />
                        {formError && (
                            <Box mb={2}>
                                <Typography variant="body2" color="error">
                                    {t("form.loginError")}
                                </Typography>
                            </Box>
                        )}
                        <div className={classes.buttons}>
                            <CustomButton
                                component={Link}
                                to="/forgot-password"
                                color="primary"
                                text={t("auth.forgotPassword")}
                            />

                            <CustomButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                icon={<ArrowRightIcon />}
                                text={t("auth.login")}
                                loading={loading}
                            />
                        </div>
                    </form>
                </Paper>
            </animated.div>
        </LayoutWrap>
    );
};

export default Login;
