import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import { parse, stringify } from "querystring";

import {
    Container,
    FormControl,
    FilledInput,
    InputLabel,
    Typography,
    InputAdornment,
    makeStyles,
    Theme,
    Grid,
    Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";

import { useAppSelector } from "hooks";
import { DateOptions, ReturnOrderType } from "types";
import { DATES } from "const";
import { Loader, RenderTitle, StatusFilter, DateFilter } from "shared";
import { PAGINATION_COUNT, RETURN_ORDER_STATUS, USER_PERMISSIONS } from "const";
import { GET_RETURN_ORDERS } from "api/Queries";

import ReturnOrderProvider from "./ReturnOrderProvider";
import ReturnOrder from "./parts/ReturnOrder";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        margin: "0 auto",
        justifyContent: "space-between",
    },
    left: {
        flex: "0 0 770px",
    },
    right: {
        flex: "0 0 140px",
        position: "relative",
    },
    formRoot: {
        width: 570,
        marginTop: theme.spacing(2),
    },
    searchInput: {
        width: "auto",
    },
}));

const ReturnOrders: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const permissions = useAppSelector((state) => state.auth.user.permissions);

    const [term, setTerm] = useState("");
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize] = useState(PAGINATION_COUNT);
    const [dateFilter, setDateFilter] = useState<DateOptions>("week");
    const [status, setStatus] = useState<RETURN_ORDER_STATUS>(RETURN_ORDER_STATUS.EMPTY);

    useEffect(() => {
        const search = history.location.search.split("?")[1];
        const parsed = parse(search);
        const pageValue = parseInt(String(parsed.page));
        const dateValue = String(parsed.date) as DateOptions | "undefined";
        const statusValue = parsed.status as RETURN_ORDER_STATUS;

        if (statusValue) {
            setStatus(statusValue);
        }

        if (dateValue && dateValue !== "undefined") {
            setDateFilter(dateValue);
        }

        if (pageValue) {
            setPage(pageValue);
        }
    }, [history.location.search]);

    const handleChange = (page: number) => {
        setPage(Math.floor(page));

        const val = String(page);
        const search = history.location.search.split("?")[1];

        let parsed = parse(search);
        parsed.page = val;
        let newString = stringify(parsed);

        history.push(history.location.pathname + "?" + newString);
    };

    const {
        loading,
        data: orderData,
        refetch,
    } = useQuery(GET_RETURN_ORDERS, {
        variables: {
            skip: page === 1 ? 0 : (page - 1) * pageSize,
            take: pageSize,
            term: term.length > 2 ? term : "",
            status: status !== "EMPTY" ? status : null,
            fromDate: dateFilter !== "all" ? DATES[dateFilter] : null,
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
    });

    useEffect(() => {
        if (orderData && orderData.returnOrders) {
            const total = orderData.returnOrders.totalCount;
            setTotalPages(Math.ceil(total / pageSize));
        }
    }, [orderData, pageSize]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTerm(value);
    };

    return (
        <ReturnOrderProvider>
            <Container maxWidth="lg" className={classes.root}>
                <div className={classes.left}>
                    <RenderTitle element="h1" text={t("returnShipments.title")} line={true} />

                    <Typography variant="body1">
                        {permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders) &&
                            t("returnShipments.introText")}
                        {permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) &&
                            t("returnShipments.introLogisticsText")}
                        {permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) &&
                            t("returnShipments.introOrderText")}
                    </Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl variant="filled" className={classes.formRoot}>
                                <InputLabel htmlFor="filled-adornment-search">
                                    {t("returnShipments.searchLabel")}
                                </InputLabel>
                                <FilledInput
                                    id="filled-adornment-search"
                                    value={term}
                                    onChange={handleSearch}
                                    className={classes.searchInput}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <DateFilter
                                id="statusSelector"
                                value={dateFilter}
                                onChange={(value: DateOptions) => setDateFilter(value)}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <StatusFilter id="statusSelector" value={status} onChange={(value) => setStatus(value)} />
                        </Grid>
                    </Grid>

                    {orderData &&
                        orderData.returnOrders.items.length > 0 &&
                        orderData.returnOrders.items.map((returnOrder: ReturnOrderType) => (
                            <ReturnOrder key={returnOrder.contentItemId} refetch={refetch} returnOrder={returnOrder} />
                        ))}
                    <Box pt={4}>
                        {orderData && orderData.returnOrders.items.length <= 0 && (
                            <Typography variant="body1" color="primary">
                                {t("returns.noReturns")}
                            </Typography>
                        )}
                        {loading && (
                            <div>
                                <Loader />
                            </div>
                        )}
                    </Box>
                </div>
                <div className={classes.right} />
            </Container>
            <Container maxWidth="lg" className={classes.root}>
                <div className={classes.left}>
                    <Grid container justifyContent="center">
                        {totalPages > 1 && (
                            <Pagination
                                color="primary"
                                count={Math.round(totalPages)}
                                page={page}
                                onChange={(event, page) => handleChange(page)}
                            />
                        )}
                    </Grid>
                </div>
            </Container>
        </ReturnOrderProvider>
    );
};

export default ReturnOrders;
