const validatePhoneNumber = (phoneNumber: string | undefined) => {
    if (phoneNumber) {
        let test = /^[0-9]\d{7,9}$/gm;
        return phoneNumber.match(test);
    } else {
        return true;
    }
};

export default validatePhoneNumber;
