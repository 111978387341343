import React from "react";
import { Switch } from "react-router-dom";
import { Header, SnackBar } from "shared";
import { PrivateRoute, PublicRoute } from "routes";

import Orders from "components/Orders";
import Contact from "components/Contact";
import NotFound from "components/NotFound";
import UserInfo from "components/UserInfo";
import Impersonate from "components/Impersonate";
import EditReturn from "components/EditReturn";
import ReturnOrders from "components/ReturnOrders";
import ReturnPolicy from "components/ReturnPolicy";
import ReturnSuccess from "components/ReturnSuccess";
import CustomerSwitch from "components/CustomerSwitch";
import { Login, ForgotPassword, ResetPassword } from "components/Auth";

const App: React.FC = () => {
    return (
        <>
            <Header />
            <Switch>
                <PublicRoute path="/login" exact={true} component={Login} />
                <PublicRoute path="/forgot-password" exact={true} component={ForgotPassword} />
                <PublicRoute path="/reset-password" component={ResetPassword} />
                <PrivateRoute path="/location-select" exact={true} component={CustomerSwitch} />
                <PrivateRoute path="/user" component={UserInfo} />
                <PrivateRoute path="/impersonate" exact={true} component={Impersonate} />
                <PrivateRoute path="/orders" exact={true} component={Orders} />
                <PrivateRoute path="/orders/edit-return" exact={true} component={EditReturn} />
                <PrivateRoute path="/orders/edit-return/succes" exact={true} component={ReturnSuccess} />
                <PrivateRoute path="/return-orders" exact={true} component={ReturnOrders} />
                <PrivateRoute path="/return-policy" exact={true} component={ReturnPolicy} />
                <PrivateRoute path="/contact" exact={true} component={Contact} />
                <PrivateRoute path="" component={NotFound} />
            </Switch>
            <SnackBar />
        </>
    );
};

export default App;
