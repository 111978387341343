const FormatFilelistToString = (value: FileList, maxCharacters = 30) => {
    if (!value) return "";

    const listLength = value.length;

    let fileString = "";

    for (var i = 0; i < listLength; i++) {
        if (listLength === 1 || i === listLength - 1) {
            fileString += value[i].name;
        } else {
            fileString += value[i].name + ", ";
        }
    }

    if (fileString.length > maxCharacters) {
        var sub = fileString.substring(0, maxCharacters);
        return sub + "...";
    } else {
        return fileString;
    }
};

export default FormatFilelistToString;
