import React from "react";
import { useTranslation } from "react-i18next";

import { Divider, Grid, Theme, Typography, makeStyles, Box, Link } from "@material-ui/core";

import { RETURN_METHOD } from "const";
import { ReturnOrderLineType, ReturnOrderType } from "types";
import { convertDate, renderMaxTextLength } from "helpers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
    },
}));

type Props = {
    returnOrderLine: ReturnOrderLineType;
    returnOrder: ReturnOrderType;
};

const ReturnOrderLineDetail: React.FC<Props> = ({ returnOrderLine, returnOrder }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Box pl={4} pr={4} pt={2} pb={2}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">{returnOrderLine.articleNumber}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">{returnOrderLine.quantity}x</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="subtitle2">
                                {renderMaxTextLength(returnOrderLine.productName || "", 70, true)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box pl={4} pr={4} pt={2} pb={2}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={2}>
                            <Box className={classes.flexBox}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t("orderNumber")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {returnOrder.orderId}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box className={classes.flexBox}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t("returnReasonLabel")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {returnOrderLine.reason && t(`returnReason.${returnOrderLine.reason}`)}
                                </Typography>
                            </Box>
                        </Grid>
                        {returnOrder && returnOrder.creator && (
                            <Grid item xs={3}>
                                <Box className={classes.flexBox}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {t("returnCreator")}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        {returnOrder.creator}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        {returnOrderLine.media && returnOrderLine.media.length > 0 && (
                            <Grid item xs={4}>
                                <Box className={classes.flexBox}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {t("documents")}
                                    </Typography>
                                    <div className={classes.flexBox}>
                                        {returnOrderLine.media?.map((item, idx: number) => {
                                            var splitName = item && item.split("/");
                                            var name = splitName[splitName.length - 1];

                                            return (
                                                <Link key={idx} href={item} target="_blank" rel="noreferrer">
                                                    {name}
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </div>
            {returnOrder.returnMethod === RETURN_METHOD.PICKUP && (
                <div key={returnOrderLine.contentItemId} className={classes.root}>
                    <Box pl={4} pr={4} pt={2} pb={2}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={5}>
                                <Box className={classes.flexBox}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {returnOrder.finalPickupDate !== null
                                            ? t("finalPickupDateLabel")
                                            : t("pickupDateLabel")}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        {returnOrder.finalPickupDate !== null
                                            ? convertDate(returnOrder.finalPickupDate)
                                            : returnOrder.pickupDate && convertDate(returnOrder.pickupDate)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box className={classes.flexBox}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Ophaaladres
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        {returnOrder.street}, {returnOrder.postalCode} {returnOrder.city}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </React.Fragment>
    );
};

export default ReturnOrderLineDetail;
