import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import { Container, Typography, makeStyles, Theme, Grid, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useAppSelector, useOrder, useSnackBar } from "hooks";
import { ReturnOrderLineType, ReturnOrderType } from "types";
import { isOrderValid } from "helpers";
import { RenderTitle } from "shared";
import { selectCount } from "store/slices/OrderSlice";
import { RETURN_METHOD, RETURN_REASON } from "const";
import { SAVE_RETURN_ORDERS } from "api/Mutations";
import { useMutation } from "@apollo/client";

import ReturnOrder from "./parts/ReturnOrder";
import ReturnCounter from "./parts/ReturnCounter";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        margin: "0 auto",
        justifyContent: "space-between",
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    heading: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4),
        borderRadius: "0 5px 0 5px",
        boxShadow: "0 5px 20px 0 rgba(87,86,86,0.15)",
    },
    datePicker: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ced5d8",
        height: 40,
        padding: theme.spacing(1, 0, 1, 2),
        marginBottom: 0,
        marginTop: theme.spacing(2),
    },
    addressWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(4),
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    noLine: {
        "&::after": {
            display: "none",
        },
    },
    left: {
        flex: "0 0 770px",
    },
    right: {
        flex: "0 0 140px",
        position: "relative",
    },
}));

const EditReturn: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const token = Cookies.get("jwt-nijburg");
    const currentLocation = Cookies.get("client-nijburg");

    const [offset, setOffset] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [allOrdersValid, setAllOrdersValid] = useState<boolean>(false);
    const [errors] = useState<string[]>([]);

    const returnOrders = useAppSelector((state) => state.order.returnOrders);
    const counter = useAppSelector((state) => selectCount(state.order));

    const [saveOrder] = useMutation(SAVE_RETURN_ORDERS);
    const { clearOrderState } = useOrder();
    const { snackBarApi } = useSnackBar();

    const ordersWrap = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let div = ordersWrap.current;
        if (div) {
            setOffset(div.offsetTop);
        }
    }, [returnOrders]);

    useEffect(() => {
        let validOrders: boolean[] = [];
        returnOrders.forEach((order) => {
            validOrders.push(isOrderValid(order))
        });
        setAllOrdersValid(validOrders.every(Boolean));
    }, [returnOrders])

    const sendOrders = () => {


        if(!allOrdersValid) {
            snackBarApi.showSnackBar("Retourneringen niet ingediend", "error");
            return;
        }
        // for each return order, first we need to save the documents, then we can save the order itself
        returnOrders.forEach((order) => {
            setLoading(true);

            // Validate the fields in each order separate
            if (!isOrderValid(order)) {
                setLoading(false);
                return;
            }

            // If none of the order lines contain files, save the order directly
            if (order.lines.every(line => line?.tempFiles == null || line.tempFiles.length === 0)) {
                saveOrderToApi(order);
                return;
            }

            order.lines.forEach(async (line: ReturnOrderLineType) => {
                const formData = new FormData();
                formData.append("orderNumber", order.orderId);
                formData.append("articleNumber", line.articleNumber);

                if (line.tempFiles) {
                    for (let key of Object.keys(line.tempFiles)) {
                        if (key !== "length") {
                            const numberKey = parseInt(key);
                            formData.append("files", line.tempFiles[numberKey]);
                        }
                    }
                }

                const headers = {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                    type: "formData",
                    Authorization: `Bearer ${token}`,
                    nijburgcustomerid: `${currentLocation}`,
                };

                await axios
                    .post(`${process.env.REACT_APP_API_URL}api/nijburg/ReturnOrderMedia`, formData, {
                        headers: headers,
                    })
                    .then(() => {
                        saveOrderToApi(order);
                    });
            });
        });
    };

    const saveOrderToApi = (order: ReturnOrderType) => {
        //convert Order lines to Mutation Format
        const newLines = order.lines.map((line: ReturnOrderLineType) => ({
            articleNumber: line.articleNumber,
            quantity: line.returnCount,
            reason: RETURN_REASON[line.reason],
        }));

        saveOrder({
            variables: {
                city: order.city,
                contactPerson: order.contactPerson,
                lines: newLines,
                orderId: order.orderId,
                phoneNumber: order.phoneNumber,
                pickupDate: order.pickupDate,
                postalCode: order.postalCode,
                returnMethod: RETURN_METHOD[order.returnMethod],
                street: order.street,
            },
        })
            .then((resp) => {
                setLoading(false);
                // @ts-ignore
                if (!resp.data.returnOrders) return;
                clearOrderState();
                snackBarApi.showSnackBar("Retournering succesvol ingediend", "success");
                history.push("/orders/edit-return/succes");
            })
            .catch(() => {
                setLoading(false);
                snackBarApi.showSnackBar("Retournering niet ingediend", "error");
            });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.left}>
                <div className={classes.wrap}>
                    <Grid className={classes.heading} container direction="row" justifyContent="space-between">
                        <Grid item xs={8} className={classes.header}>
                            <RenderTitle element="h1" text={t("returns.title")} />
                            <RenderTitle element="h2" text={`${counter} ${t("returns.articles")}`} line={true} />
                            <Typography variant="body1">{t("returns.introText")}</Typography>
                        </Grid>
                        <Grid item>
                            <Button component={Link} to="/orders" color="primary" startIcon={<AddIcon />}>
                                {t("returns.addButtonText")}
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Render all the return order from the state to create a new return */}
                    <div ref={ordersWrap}>
                        {returnOrders.map((returnOrder: ReturnOrderType) => {
                            return returnOrder.lines.length > 0 ? (
                                <ReturnOrder
                                    key={returnOrder.contentItemId}
                                    error={errors.includes(returnOrder.contentItemId)}
                                    returnOrder={returnOrder}
                                />
                            ) : null;
                        })}
                    </div>
                </div>
            </div>
            <div className={classes.right}>
                <ReturnCounter
                    offsetTop={offset}
                    handleOrdersSend={sendOrders}
                    loading={loading}
                    allOrdersValid={allOrdersValid}
                    orderCount={returnOrders.length}
                />
            </div>
        </Container>
    );
};

export default EditReturn;
