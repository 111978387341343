import { DECLINE_RETURN_REASON, RETURN_ORDER_STATUS, RETURN_REASON } from "const";
import { Dispatch, SetStateAction, useState } from "react";
import ReturnOrderContext from "./ReturnOrderContext";

export type ReturnOrderApi = {
    order: ReturnStatusOrderType;
    api: {
        setOrder: Dispatch<SetStateAction<ReturnStatusOrderType>>;
    };
};

type ReturnStatusOrderType = {
    contentItemId: string;
    status?: RETURN_ORDER_STATUS;
    reason?: RETURN_REASON | DECLINE_RETURN_REASON;
    lines?: ReturnStatusOrderLineType[];
    pickupDate?: Date;
};

type ReturnStatusOrderLineType = {
    contentItemId: string;
    status?: RETURN_ORDER_STATUS;
    reason?: RETURN_REASON | DECLINE_RETURN_REASON;
};

const ReturnOrderProvider: React.FC = ({ children }) => {
    const [order, setOrder] = useState<ReturnStatusOrderType>({
        contentItemId: "",
    });

    const value: ReturnOrderApi = {
        order: order,
        api: {
            setOrder: setOrder,
        },
    };

    return <ReturnOrderContext.Provider value={value}>{children}</ReturnOrderContext.Provider>;
};

export default ReturnOrderProvider;
