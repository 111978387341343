import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { OrderType, OrderLineType, ReturnOrderType, ReturnOrderLineType } from "types";

// Define a type for the slice state
export type OrderState = {
    currentOrder: OrderType | undefined;
    currentArticle: OrderLineType | undefined;
    returnOrders: ReturnOrderType[];
};

// Define the initial state using that type
const initialState: OrderState = {
    currentOrder: undefined,
    currentArticle: undefined,
    returnOrders: [],
};

export const OrderSlice = createSlice({
    name: "order",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCurrentOrder: (state, action: PayloadAction<OrderType>) => {
            state.currentOrder = action.payload;
        },
        setCurrentOrderLine: (state, action: PayloadAction<OrderLineType>) => {
            state.currentArticle = action.payload;
        },
        addReturnOrders: (state, action: PayloadAction<ReturnOrderType[]>) => {
            state.returnOrders = action.payload;
            state.currentArticle = undefined;
            state.currentOrder = undefined;
        },
        clearState: (state) => {
            state.currentArticle = undefined;
            state.currentOrder = undefined;
            state.returnOrders = [];
        },
    },
});

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: OrderState) => {
    let count = 0;
    state.returnOrders.forEach((returnOrder: ReturnOrderType) => {
        returnOrder.lines.forEach((orderLine: ReturnOrderLineType) => {
            if (orderLine.returnCount > 0) {
                var newCount = count + orderLine.returnCount;
                count = newCount;
            }
        });
    });
    return count;
};

export const { setCurrentOrder, setCurrentOrderLine, addReturnOrders, clearState } = OrderSlice.actions;

export default OrderSlice.reducer;
