import { useHistory } from "react-router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Container, Typography, makeStyles, Theme } from "@material-ui/core";

import { USER_PERMISSIONS } from "const";
import { useAppSelector } from "hooks";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(6, 0),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const permissions = useAppSelector((state) => state.auth.user.permissions);

    useEffect(() => {
        if (permissions && history.location.pathname === "/") {
            if (permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders)) {
                history.push("/orders");
            } else if (
                permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) ||
                permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) ||
                permissions.includes(USER_PERMISSIONS.Nijburg_CanImpersonate) ||
                permissions.includes(USER_PERMISSIONS.Nijburg_CanImpersonate)
            ) {
                history.push("/return-orders");
            }
        }
    });

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <Typography variant="h1">{t("404.title")}</Typography>
                <Typography variant="body1">{t("404.introText")}</Typography>
            </div>
        </Container>
    );
};

export default NotFound;
