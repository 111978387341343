import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useSnackBar from "hooks/useSnackBar";

const SnackBar = () => {
    const { open, message, type, duration, snackBarApi } = useSnackBar();

    const handleClose = () => {
        snackBarApi.closeSnackBar();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
        >
            <Alert onClose={handleClose} severity={type}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBar;
