import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
    makeStyles,
    Theme,
    Paper,
    Typography,
    Button,
    Box,
    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
    CircularProgress,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { useSnackBar } from "hooks";
import { RESET_PASSWORD } from "api/Mutations";

import { AuthAnimation } from "theme/Animations";
import Logo from "assets/images/logo.svg";
import LayoutWrap from "./LayoutWrap";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CustomButton } from "shared";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "0 0 500px",
        alignSelf: "flex-start",
        zIndex: 10,
        marginTop: "5%",
        minWidth: 500,
    },
    paper: {
        minHeight: 460,
        borderRadius: "0 10px 0 10px",
        padding: theme.spacing(10),
        boxShadow: "0 15px 40px 0 rgba(87,86,86,0.15)",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(4, 0),
    },
    logo: {
        marginBottom: theme.spacing(8),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: theme.spacing(4),
    },
    loginButton: {
        marginTop: theme.spacing(4),
    },
    list: {
        marginTop: 8,
        paddingLeft: 16,
    },
    label: {
        position: "relative",
        transform: "none",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: theme.palette.text.primary,
        marginBottom: 10,
        "& + .MuiInput-formControl": {
            marginTop: theme.spacing(2),
        },
    },
    input: {
        background: "#fff",
        padding: "15px 15px",
        fontSize: 14,
        "&::before": {
            display: "none",
        },
        "&::after": {
            display: "none",
        },
        "&.Mui-error": {
            border: "1px solid red",
        },
    },
}));

const ResetPassword: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const { snackBarApi } = useSnackBar();
    const AnimationProps = useSpring(AuthAnimation);
    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordSuccess, setPasswordSuccess] = useState(false);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setPassword(val);
    };

    useEffect(() => {
        if (!location.search) return;
        let search = location.search;
        const splitLocation = search.split("&");
        let email = splitLocation[0].split("email=")[1];
        let token = splitLocation[1].split("code=")[1];

        if (token) {
            setToken(token);
        }
        if (email) {
            setEmail(email);
        }
    }, [location]);

    const handleSubmit = () => {
        setPasswordError(false);

        if (validatePassword(password)) {
            setPasswordError(true);
            return;
        }

        resetPassword({
            variables: {
                resetToken: token,
                newPassword: password,
                email: email,
            },
        })
            .then((resp) => {
                if (resp.data.resetPassword) {
                    snackBarApi.showSnackBar(t("snackbar.resetPassword.succes"), "success");
                    setPasswordSuccess(true);
                } else {
                    snackBarApi.showSnackBar(t("snackbar.resetPassword.error"), "error");
                }
            })
            .catch(() => {
                snackBarApi.showSnackBar(t("snackbar.resetPassword.error"), "error");
            });
    };

    const validatePassword = (password: string) => {
        let hasError = false;
        if (/^(?=\S{8,})/.test(password) === false) {
            hasError = true;
        }

        if (/^.*(?=\d).+(?=\d)/.test(password) === false) {
            hasError = true;
        }

        if (/^.*(?=[A-Z])/.test(password) === false) {
            hasError = true;
        }

        if (/^(?=\d{4,})/.test(password) === true) {
            hasError = true;
        }
        return hasError;
    };

    return (
        <LayoutWrap>
            <animated.div className={classes.root} style={AnimationProps}>
                <Paper className={classes.paper}>
                    <div className={classes.logo}>
                        <img src={Logo} alt="Nijburg Industry Group" />
                    </div>
                    <Typography variant="h1">{t("auth.passwordReset")}</Typography>

                    {!passwordSuccess && (
                        <>
                            <Typography variant="body1" color="textPrimary">
                                {t("auth.passwordRestText")}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {t("auth.passwordRequirementsText")}{" "}
                            </Typography>
                            <ul className={classes.list}>
                                <li>{t("auth.passwordRequirementsList.option1")}</li>
                                <li>{t("auth.passwordRequirementsList.option2")}</li>
                                <li>{t("auth.passwordRequirementsList.option3")}</li>
                            </ul>

                            <form className={classes.form} noValidate>
                                <InputLabel
                                    htmlFor="password"
                                    classes={{
                                        root: classes.label,
                                    }}
                                >
                                    {t("auth.changePasswordLabel")}
                                </InputLabel>
                                <OutlinedInput
                                    id="password"
                                    classes={{
                                        input: classes.input,
                                    }}
                                    onChange={handlePasswordChange}
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {passwordError && (
                                    <Box pt={1} pb={2}>
                                        <Typography variant="body2" color="error">
                                            {t("auth.passwordResetError")}
                                        </Typography>
                                    </Box>
                                )}
                                <div className={classes.buttons}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={
                                            !loading ? (
                                                <ArrowRightIcon />
                                            ) : (
                                                <CircularProgress color="inherit" size="1em" />
                                            )
                                        }
                                        onClick={() => handleSubmit()}
                                    >
                                        {t("auth.changePassword")}
                                    </Button>
                                </div>
                            </form>
                        </>
                    )}
                    {passwordSuccess && (
                        <Box pt={2}>
                            <Typography variant="body1" color="textSecondary">
                                {t("auth.passwordResetSuccess")}
                            </Typography>
                            <CustomButton
                                component={Link}
                                variant="contained"
                                to="/login"
                                color="primary"
                                text={t("auth.login")}
                            />
                        </Box>
                    )}
                </Paper>
            </animated.div>
        </LayoutWrap>
    );
};

export default ResetPassword;
