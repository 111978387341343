import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

import { makeStyles, Theme, Paper, Typography, TextField, Button, Box, CircularProgress } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { AuthAnimation } from "theme/Animations";
import Logo from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import LayoutWrap from "./LayoutWrap";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "api/Mutations";
import { validateEmail } from "helpers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "0 0 500px",
        position: "relative",
        alignSelf: "flex-start",
        zIndex: 10,
        marginTop: "5%",
        transform: "translateX(10vw)",
        minWidth: 500,
        maxWidth: 500,
    },
    paper: {
        minHeight: 460,
        borderRadius: `0 ${theme.shape.borderRadius * 2}px 0 ${theme.shape.borderRadius * 2}px`,
        padding: theme.spacing(10),
        boxShadow: "0 15px 40px 0 rgba(87,86,86,0.15)",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(4),
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    logo: {
        marginBottom: theme.spacing(8),
    },
    loginButton: {
        marginTop: 5,
    },
}));

const ForgotPassword: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("");
    const [sendSuccess, setSendSuccess] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);

    const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

    const handleSubmit = () => {
        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }
        forgotPassword({
            variables: {
                email: email,
            },
        }).then((resp) => {
            if (resp.data.forgotPassword) {
                setSendSuccess(true);
            } else {
            }
        });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
    };

    const AnimationProps = useSpring(AuthAnimation);

    return (
        <LayoutWrap>
            <animated.div className={classes.root} style={AnimationProps}>
                <Paper className={classes.paper}>
                    <div className={classes.logo}>
                        <img src={Logo} alt="Nijburg Industry Group" />
                    </div>
                    <Typography variant="h1">{t("auth.forgotPassword")}</Typography>
                    {!sendSuccess && (
                        <>
                            <Typography variant="body1" color="textSecondary">
                                {t("auth.forgotPasswordText")}
                            </Typography>

                            <form className={classes.form} noValidate>
                                <TextField
                                    id="email"
                                    label={t("form.email")}
                                    value={email}
                                    type="email"
                                    onChange={handleEmailChange}
                                    variant="outlined"
                                />
                                {emailError && (
                                    <Typography color="error" variant="body2">
                                        {t("auth.emailError")}
                                    </Typography>
                                )}
                                <div className={classes.buttons}>
                                    <Button component={Link} to="/login" color="primary">
                                        {t("auth.login")}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={email.length < 2}
                                        color="primary"
                                        endIcon={
                                            !loading ? (
                                                <ArrowRightIcon />
                                            ) : (
                                                <CircularProgress color="inherit" size="1em" />
                                            )
                                        }
                                        onClick={() => handleSubmit()}
                                    >
                                        {t("auth.send")}
                                    </Button>
                                </div>
                            </form>
                        </>
                    )}
                    {sendSuccess && (
                        <Box pt={2}>
                            <Typography variant="body1" color="textSecondary">
                                {t("auth.emailSuccesMessage")}
                            </Typography>
                            <Button
                                component={Link}
                                className={classes.loginButton}
                                variant="contained"
                                to="/login"
                                color="primary"
                            >
                                {t("auth.login")}
                            </Button>
                        </Box>
                    )}
                </Paper>
            </animated.div>
        </LayoutWrap>
    );
};

export default ForgotPassword;
