import { useState } from "react";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Typography, makeStyles, Theme, Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { AuthService } from "services";
import { USER_ROLES } from "const";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flex: "0 0 calc((100vw - 970px) / 2)",
        minWidth: 400,
    },
    link: {
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        color: theme.palette.common.white,
        cursor: "pointer",
        width: "calc((100vw - 970px) / 2)",
        borderRadius: `0 10px 0 10px`,
        padding: 0,
        textTransform: "none",
        maxWidth: "220px",
        minWidth: "220px",
        "& .MuiButton-label": {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: theme.spacing(0, 5),
        },
        "&:hover": {
            textDecoration: "none",
            backgroundColor: theme.palette.primary.dark,
        },
    },
    menuTrigger: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        marginLeft: theme.spacing(6),
    },
    paper: {
        width: "calc((100vw - 970px) / 2)",
        borderRadius: "0 0 0 20px",
        marginTop: "-10px",
        maxWidth: 220,
        minWidth: 220,
        boxShadow: "0 2px 4px 0 rgba(87,86,86,0.15)",
    },
    list: {
        margin: 0,
        padding: 0,
    },
    logout: {
        backgroundColor: "#eee",
        padding: theme.spacing(2, 5),
        borderTop: "1px solid #CED5D8",
        textTransform: "uppercase",
        fontSize: "0.8rem",
    },
    menuLink: {
        padding: theme.spacing(1, 5),
        fontSize: "0.8rem",
    },
}));

const UserMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const auth = AuthService();
    const { t } = useTranslation();
    const currentUser = useAppSelector((state) => state.auth.user);
    const customers = useAppSelector((state) => state.auth.user.allowedCustomers);
    const isImpersonating = useAppSelector((state) => state.auth.user.impersonating);

    const userRole = currentUser.role;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStopImpersonating = () => {
        auth.userStopImpersonating();
        handleClose();
    }

    const handleLogOut = () => {
        auth.userLogout();
    };

    return (
        <div className={classes.root}>
            <Button aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick} className={classes.link}>
                <Typography className={classes.menuTrigger} variant="subtitle1" color="inherit" noWrap={true}>
                    {!currentUser.firstName && !currentUser.lastName ? currentUser.email : currentUser.firstName + " " + currentUser.lastName}
                    <ArrowDropDownIcon className={classes.button} />
                </Typography>
                <Typography variant="body1" color="inherit">
                    {t(`roles.${userRole}`)}
                </Typography>
            </Button>
            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{
                    paper: classes.paper,
                    list: classes.list,
                }}
            >
                <MenuItem className={classes.menuLink} component={Link} to="/user">
                    {t("userMenu.account")}
                </MenuItem>
                {customers && customers?.length > 1 && (
                    <MenuItem className={classes.menuLink} component={Link} to="/location-select">
                        Kies Vestiging
                    </MenuItem>
                )}
                {userRole === USER_ROLES.Nijburg_Admin && (
                    <MenuItem className={classes.menuLink}>{t("userMenu.users")}</MenuItem>
                )}
                {isImpersonating && (
                    <MenuItem className={classes.logout} onClick={handleStopImpersonating}>
                        {t("userMenu.stopImpersonating")}
                    </MenuItem>
                )}
                <MenuItem className={classes.logout} onClick={handleLogOut}>
                    {t("userMenu.logout")}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
