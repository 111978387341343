import { subDays, subMonths, subWeeks, subYears } from "date-fns";
const today = new Date();

// EMPTY reason is only used in the frontend as empty select state
enum RETURN_REASON {
    EMPTY = "EMPTY",
    DAMAGED = "DAMAGED",
    GOODS_DEFECT = "GOODS_DEFECT",
    INCORRECT_ARTICLE_DELIVERED = "INCORRECT_ARTICLE_DELIVERED",
    INCORRECT_ARTICLE_QUANTITY_DELIVERED = "INCORRECT_ARTICLE_QUANTITY_DELIVERED",
    INCORRECT_ARTICLE_ORDERED = "INCORRECT_ARTICLE_ORDERED",
    INCORRECT_ARTICLE_QUANTITY_ORDERED = "INCORRECT_ARTICLE_QUANTITY_ORDERED",
}

enum RETURN_ORDER_STATUS {
    EMPTY = "EMPTY",
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
    DECLINED = "DECLINED",
}

enum RETURN_METHOD {
    SEND = "SEND",
    DELIVER = "DELIVER",
    PICKUP = "PICKUP",
}

enum DECLINE_RETURN_REASON {
    EMPTY = "EMPTY",
    ARTICLE_NOT_RETURNABLE = "ARTICLE_NOT_RETURNABLE",
    ARTICLE_DOES_NOT_MATCH_RULES = "ARTICLE_DOES_NOT_MATCH_RULES",
}

enum USER_ROLES {
    "Nijburg_Customer" = "Nijburg_Customer",
    "Nijburg_Sales" = "Nijburg_Sales",
    "Nijburg_Logistics" = "Nijburg_Logistics",
    "Nijburg_Order" = "Nijburg_Order",
    "Nijburg_Admin" = "Nijburg_Admin",
    "Administrator" = "Administrator",
}

enum USER_PERMISSIONS {
    "Nijburg_ManageOwnReturnOrders" = "Nijburg_ManageOwnReturnOrders", // customer rol
    "Nijburg_ManageOthersReturnOrders" = "Nijburg_ManageOthersReturnOrders", // order roll
    "Nijburg_CanImpersonate" = "Nijburg_CanImpersonate", // sales rol
    "Nijburg_ManageLogistics" = "Nijburg_ManageLogistics", // logistics rol
    "ExecuteGraphQL" = "ExecuteGraphQL",
    "ExecuteGraphQLMutations" = "ExecuteGraphQLMutations",
}

const PAGINATION_COUNT = 10;

const DATES = {
    all: "All",
    year: new Date(subYears(today, 1)).toUTCString(),
    threeMonths: new Date(subMonths(today, 3)).toUTCString(),
    month: new Date(subMonths(today, 1)).toUTCString(),
    week: new Date(subWeeks(today, 1)).toUTCString(),
    yesterday: new Date(subDays(today, 1)).toUTCString(),
    today: new Date(today).toUTCString(),
};

export {
    RETURN_REASON,
    RETURN_ORDER_STATUS,
    RETURN_METHOD,
    DECLINE_RETURN_REASON,
    USER_ROLES,
    USER_PERMISSIONS,
    PAGINATION_COUNT,
    DATES,
};
