import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { InputLabel, makeStyles, Theme, Typography } from "@material-ui/core";
import { RETURN_METHOD } from "const";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    wrap: {
        display: "flex",
    },
    label: {
        marginBottom: theme.spacing(2),
    },
    button: {
        border: "1px solid #ced5d8",
        flex: 1,
        padding: "10px",
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:first-child": {
            borderRadius: "0 0 0 10px",
        },
        "&:last-child": {
            borderRadius: "0 10px 0 0",
        },
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        "& p": {
            color: theme.palette.common.white,
        },
    },
}));

type Props = {
    onChange: (val: string, id: string) => void;
    value: string;
    id: string;
    label: string;
};

const ReturnTypeSelector: React.FC<Props> = ({ onChange, value, id, label }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClick = (type: string) => {
        onChange(type, id);
    };

    return (
        <div className={classes.root}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <div className={classes.wrap}>
                {Object.keys(RETURN_METHOD).map((type: string, idx: number) => {
                    if (type === RETURN_METHOD.SEND) return null;
                    const returnType = t(`returnMethod.${type as RETURN_METHOD}`);
                    const currentType = (RETURN_METHOD as any)[type];
                    return (
                        <div
                            key={idx}
                            onClick={() => handleClick(currentType)}
                            className={clsx(classes.button, { [classes.active]: currentType === value })}
                        >
                            <Typography variant="body1" color="textSecondary">
                                {returnType}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ReturnTypeSelector;
