import React from "react";
import SnackBarContext from "shared/SnackBar/SnackBarContext";

function useReturnOrder() {
    const context = React.useContext(SnackBarContext);
    if (context === undefined) {
        throw new Error("No SnackBarContext found");
    }
    return context;
}

export default useReturnOrder;
