import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { parse, stringify } from "querystring";

import {
    Container,
    FormControl,
    FilledInput,
    InputLabel,
    Typography,
    InputAdornment,
    makeStyles,
    Theme,
    Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { OrderType } from "types";
import { PAGINATION_COUNT } from "const";
import { GET_ALL_ORDERS } from "api/Queries";

import { Loader, RenderTitle } from "shared";
import { OrderDialog, ArticleCounter, OrderRow } from "./parts";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        margin: "0 auto",
        justifyContent: "space-between",
    },
    left: {
        flex: "0 0 770px",
    },
    right: {
        flex: "0 0 140px",
        position: "relative",
    },
    formRoot: {
        width: 680,
        margin: theme.spacing(4, 0),
    },
    searchInput: {
        width: "auto",
    },
    countItem: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(4),
        position: "sticky",
        top: "292px",
    },
    countText: {
        marginBottom: theme.spacing(2),
    },
    countButton: {
        fontWeight: 700,
    },
}));

const Orders: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [term, setTerm] = useState("");
    const [offset, setOffset] = useState(0);
    const [page, setPage] = React.useState(1);
    const [pageSize] = useState(PAGINATION_COUNT);
    const [totalPages, setTotalPages] = useState(0);
    const [openPopUp, setOpenPopUp] = useState(false);

    const ordersWrap = useRef<HTMLDivElement>(null);

    // use the url params to set the page after a refresh
    useEffect(() => {
        if (history.location.search) {
            const search = history.location.search.split("?")[1];
            let parsed = parse(search);
            let value = parseInt(String(parsed.page));
            if (value > 0) {
                setPage(value);
            }
        }
    }, [history.location.search]);

    const { loading, error, data } = useQuery(GET_ALL_ORDERS, {
        variables: {
            skip: (page - 1) * pageSize,
            take: pageSize,
            term: term.length > 2 ? term.trim() : "",
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
    });

    useEffect(() => {
        let div = ordersWrap.current;
        if (div) {
            setOffset(div.offsetTop);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.orders) {
            const total = data.orders.totalCount;
            setTotalPages(Math.round(total / pageSize));
        }
    }, [data, pageSize]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTerm(value);
    };

    const handleChange = (page: number) => {
        setPage(Math.floor(page));
        const val = String(page);
        const search = history.location.search.split("?")[1];

        let parsed = parse(search);
        parsed.page = val;
        let newString = stringify(parsed);

        history.push(history.location.pathname + "?" + newString);
    };

    return (
        <>
            <Container maxWidth="lg" className={classes.root}>
                <div className={classes.left}>
                    <RenderTitle element="h1" text={t("orders.title")} marginSize={"small"} line={true} />
                    <Typography variant="body1">{t("orders.introText")}</Typography>
                    <FormControl className={classes.formRoot} variant="filled">
                        <InputLabel htmlFor="filled-adornment-search">{t("orders.searchLabel")}</InputLabel>
                        <FilledInput
                            onChange={handleSearch}
                            id="filled-adornment-search"
                            value={term}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            className={classes.searchInput}
                        />
                    </FormControl>
                    {loading && (
                        <div>
                            <Loader />
                        </div>
                    )}
                    {!loading && !error && data && data.orders.items.length > 0 && (
                        <React.Fragment>
                            <div ref={ordersWrap}>
                                {data.orders.items.map((order: OrderType, key: number) => (
                                    <OrderRow handlePopUp={setOpenPopUp} key={order.contentItemId} order={order} />
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                    {!loading && !error && data && data.orders.items.length <= 0 && (
                        <Typography variant="body1" color="primary">
                            {t("orders.noOrders")}
                        </Typography>
                    )}
                    {!loading && error && (
                        <Typography variant="body1" color="error">
                            {t("orders.error")}
                        </Typography>
                    )}
                </div>
                <div className={classes.right}>
                    <ArticleCounter offsetTop={offset} />
                </div>
                {openPopUp && <OrderDialog onClose={() => setOpenPopUp(false)} open={openPopUp} />}
            </Container>
            <Container maxWidth="lg" className={classes.root}>
                <div className={classes.left}>
                    <Grid container justifyContent="center">
                        {totalPages > 1 && (
                            <Pagination
                                color="primary"
                                count={Math.round(totalPages)}
                                page={page}
                                onChange={(e, page) => handleChange(page)}
                            />
                        )}
                    </Grid>
                </div>
            </Container>
        </>
    );
};

export default Orders;
