import { Box, Dialog, makeStyles, Theme, IconButton, DialogActions } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 770,
        minWidth: 770,
        backgroundColor: theme.palette.background.default,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        marginRight: 249,
    },
    paper: {
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "70px",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

type PupUpProps = {
    open: boolean;
    onClose: () => void;
    content: () => ReactNode;
    buttons: () => ReactNode;
};

const Popup = ({ open, onClose, content, buttons }: PupUpProps) => {
    const classes = useStyles();

    return (
        <Dialog classes={{ paper: classes.root, scrollPaper: classes.paper }} open={open} onClose={() => onClose()}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose()}>
                <CloseIcon />
            </IconButton>
            {content && content()}
            <Box p={2}>
                <DialogActions>{buttons && buttons()}</DialogActions>
            </Box>
        </Dialog>
    );
};

export default Popup;
