import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, Grid, Typography, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { OrderLineType, OrderType } from "types";
import { useAppSelector, useOrder } from "hooks";
import { renderMaxTextLength } from "helpers";

import OrderLine from "./OrderLine";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(6),
        display: "flex",
        flexDirection: "column",
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(2, 4),
        marginBottom: theme.spacing(2),
    },
    moreButton: {
        alignSelf: "flex-end",
    },
}));

type Props = {
    order: OrderType;
    handlePopUp: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderRow: React.FC<Props> = ({ order, handlePopUp }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { changeCurrentOrderLine, changeCurrentOrder } = useOrder();
    const stateOrders = useAppSelector((state) => state.order.returnOrders);

    const [slideCount, setSlideCount] = useState(5);
    const [openList, setOpenList] = useState(false);
    const [articleLength] = useState(order.lines.length);
    const [totalArticles, setTotalArticles] = useState(0);
    const [totalSelected, setTotalSelected] = useState(0);

    useEffect(() => {
        let count = 0;
        const lines = order.lines;
        const currentStateOrder = stateOrders.find((stateOrder) => stateOrder.contentItemId === order.contentItemId);
        if (currentStateOrder) {
            setTotalSelected(currentStateOrder.lines.length);
        }

        lines.forEach((line) => {
            count = count + line.allowedReturnQuantity;
        });

        setTotalArticles(count);
    }, [order, stateOrders]);

    const handleShowMore = () => {
        if (slideCount === articleLength) {
            setSlideCount(5);
            setOpenList(false);
        } else {
            setSlideCount(articleLength);
            setOpenList(true);
        }
    };

    const handleOpenPopup = (orderLine: OrderLineType) => {
        changeCurrentOrderLine(orderLine);
        changeCurrentOrder(order);
        handlePopUp(true);
    };

    return (
        <>
            <div className={classes.root}>
                <header className={classes.header}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={1}>
                            <Typography variant="subtitle1">{totalSelected}x</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">{order.orderId}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">{renderMaxTextLength(order.projectName)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={1}>
                            <Typography variant="subtitle2">{totalArticles}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">{order.customerId}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle2"></Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">{order.customerReference}</Typography>
                        </Grid>
                    </Grid>
                </header>

                {order.lines.slice(0, slideCount).map((orderLine: OrderLineType, idx: number) => (
                    <OrderLine key={orderLine.contentItemId} orderLine={orderLine} openPopup={handleOpenPopup} />
                ))}

                {articleLength > 5 && (
                    <Button onClick={handleShowMore} className={classes.moreButton} color="primary">
                        {openList ? (
                            <>
                                {t("showLessText")}
                                <ArrowDropUpIcon />
                            </>
                        ) : (
                            <>
                                {t("showMoreText")}
                                <ArrowDropDownIcon />
                            </>
                        )}
                    </Button>
                )}
            </div>
        </>
    );
};

export default OrderRow;
