import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contact, ImpersonateToken } from "types";
import { Box, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { RenderTitle, PopUp, Loader } from "shared";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { login, setUser } from "../../../store/slices/AuthSlice";
import { useAppDispatch } from "../../../hooks";
import { useHistory } from "react-router";
import { getUserFromToken } from "../../../helpers";
import { useLazyQuery } from "@apollo/client";
import { GET_CONTACTS } from "../../../api/Queries";
import clsx from "clsx";

type Props = {
    open: boolean;
    onClose: () => void;
    customerId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    contact: {
        marginBottom: theme.spacing(2),
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        transition: "all 250ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
    },
}));

const ContactsDialog: React.FC<Props> = ({ onClose, open , customerId}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const token = Cookies.get("jwt-nijburg");
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [loadingImpersonation, setLoadingImpersonation] = useState(false);

    const [getContacts, { loading, error, data }] = useLazyQuery(GET_CONTACTS, {
        variables: {
            customerId: customerId,

            // todo NK-200 this is an ugly quick fix. Ideally we implement a table or lazy loading.
            take: 100
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
    });

    useEffect(() => {
        getContacts()
    }, [getContacts]);


    const useContactToImpersonate = async (event: any) => {
        setLoadingImpersonation(true);
        const userId = event.currentTarget.getAttribute('data-user-id')
        let allowedCustomers: string[] = [];

        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            type: "formData",
            Authorization: `Bearer ${token}`,
        };

        await axios
            .post(`${process.env.REACT_APP_API_URL}api/nijburg/impersonation/${userId}`, null, {
                headers: headers,
            })
            .then((resp: AxiosResponse<ImpersonateToken>) => {
                const newToken = resp.data.access_token;
                const oldCookie = Cookies.get("jwt-nijburg");
                if(oldCookie) {
                    Cookies.set("jwt-nijburg-old", oldCookie, { path: "/" });
                }

                Cookies.set("jwt-nijburg", newToken, { path: "/" });
                dispatch(login());
                try {
                    const user = getUserFromToken(newToken);
                    dispatch(setUser(user));
                    allowedCustomers = user.allowedCustomers;
                    Cookies.set("client-nijburg", user.allowedCustomers[0].CustomerId, { path: "/" });
                } catch (error) {
                    console.warn("cannot decode token", error);
                } finally {
                    setLoadingImpersonation(false);
                }
            });
        allowedCustomers && allowedCustomers.length > 1 ? history.push("/location-select") : history.push("/orders");

    }


    return (
    <PopUp
        open={open}
        onClose={onClose}
        content={() => (
        <>
            <Box pl={4} pr={4} pt={4} pb={2}>
                <Box pb={2}>
                    <RenderTitle element="h3" text={t("impersonate.contactsDialog.title")} />
                    <Typography variant="subtitle2">{t("impersonate.contactsDialog.description")}</Typography>
                    <Divider/>
                </Box>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {loading && (
                        <div>
                            <Loader />
                        </div>
                    )}
                    {!loading && !error && data && data.contacts.items.length > 0 && (
                        <React.Fragment>
                            {!loadingImpersonation && data.contacts.items.map((contact: Contact, key: number) => (
                                <Grid item
                                      key={key}
                                      className={clsx(classes.contact)}
                                      data-user-id={contact.user.id}
                                      onClick={useContactToImpersonate}>
                                    <Typography variant="subtitle2">
                                        <strong>{contact.firstName} {contact.lastName}</strong> - <strong>{contact.user.email}</strong>
                                    </Typography>
                                </Grid>
                            ))}
                            {
                                loadingImpersonation && (
                                    <div>
                                        <Loader />
                                    </div>
                                )
                            }
                        </React.Fragment>
                    )}
                    {!loading && !error && data && data.contacts.items.length <= 0 && (
                        <Typography variant="body1" color="primary">
                            {t("impersonate.noContactsFound")}
                        </Typography>
                    )}
                    {!loading && error && (
                        <Typography variant="body1" color="error">
                            {t("impersonate.error")}
                        </Typography>
                    )}
                </Grid>
            </Box>
        </>
        )}
        buttons={() => <></>}
    />
    );
};

export default ContactsDialog;
