const renderMaxTextLength = (
    text: string | undefined,
    maxCharacters: number = 70,
    addMoreIndicator: boolean = true
) => {
    if (!text) return "";
    const returnString = text.trim();
    let length = maxCharacters === 0 ? 70 : maxCharacters;
    let newString = "";

    if (returnString.length >= length) {
        newString = returnString.slice(0, length);
    } else {
        newString = returnString;
    }

    if (addMoreIndicator && newString.length >= length) {
        newString = newString.slice(0, newString.length - 3);
        newString = newString + "...";
    }

    return newString;
};

export default renderMaxTextLength;
