import { makeStyles, Theme, Grid, Typography } from "@material-ui/core";
import { Customer } from "types";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(2, 4),
        marginBottom: theme.spacing(2),
    },
    moreButton: {
        alignSelf: "flex-end",
    },
}));

type Props = {
    customer: Customer;
    handlePopUp: React.Dispatch<React.SetStateAction<boolean>>;
    handleCustomerId: React.Dispatch<React.SetStateAction<string>>;
};

const OrderRow: React.FC<Props> = ({ customer, handlePopUp, handleCustomerId }) => {
    const classes = useStyles();

    const handleOpenPopup = (e: any) => {
        handlePopUp(true);
        handleClickCustomerId(e)
    };

    const handleClickCustomerId = (e: any) => {
        const customerId = e.currentTarget.getAttribute('data-customer-id');
        handleCustomerId(customerId);
    };

    return (
        <>
            <div className={classes.root}>
                <header className={classes.header}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
                          data-customer-id={customer.contentItemId} onClick={handleOpenPopup}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">{customer.customerId}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle2"/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">{customer.name}</Typography>
                        </Grid>
                    </Grid>
                </header>
            </div>
        </>
    );
};

export default OrderRow;
