import "date-fns";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/nl";
import { useMutation } from "@apollo/client";

import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    makeStyles,
    Theme,
    CircularProgress,
    InputLabel,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { USER_PERMISSIONS } from "const";
import { convertDate } from "helpers";
import { PopUp, RenderTitle } from "shared";
import { ReturnOrderType, ReturnOrderLineType } from "types";
import DialogOrderLine from "./DialogOrderLine";
import useReturnOrder from "../useReturnOrder";
import { UPDATE_FINAL_PICKUP_DATE } from "api/Mutations";
import { useAppSelector, useSnackBar } from "hooks";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(0, 4),

        "&.Mui-expanded": {
            minHeight: "auto",
        },
    },
    content: {
        "&.Mui-expanded": {
            margin: "12px 0",
        },
    },
    accordionRoot: {
        flexDirection: "column",
        padding: "10px 0",
    },
    paper: {
        backgroundColor: "transparent",
    },
    button: {
        color: theme.palette.common.white,
    },
    dialog: {
        width: 770,
        minWidth: 770,
        backgroundColor: theme.palette.background.default,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        marginRight: 240,
    },
    dialogPaper: {
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "70px",
    },
    datePicker: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ced5d8",
        height: 40,
        padding: theme.spacing(1, 0, 1, 2),
        marginBottom: 0,
        marginTop: theme.spacing(2),
    },
    addressWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    dateWrap: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.common.white,
    },
}));

type Props = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    returnOrder: ReturnOrderType;
    refetch: any;
};

const ReturnDialog: React.FC<Props> = ({ open, setOpen, returnOrder, refetch }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { order, api } = useReturnOrder();
    const [updatePickUpDate, { loading }] = useMutation(UPDATE_FINAL_PICKUP_DATE);
    const [error, setError] = useState(false);
    const permissions = useAppSelector((state) => state.auth.user.permissions);
    const [formatDate, setFormatDate] = useState(order?.pickupDate);
    const { snackBarApi } = useSnackBar();

    if (locale && locale.options) {
        if (i18n.language === "nl") {
            locale.options.weekStartsOn = 1;
        }
    }

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    useEffect(() => {
        const orderDate = order.pickupDate;
        const today = new Date();

        if (!orderDate || orderDate <= today) {
            today.setDate(today.getDate() + 1);
            setFormatDate(today);
        } else {
            setFormatDate(orderDate);
        }
    }, [order]);

    const handleOrderChange = (value: MaterialUiPickersDate, name: string) => {
        const tempOrder = { ...order, [name]: value };
        api.setOrder(tempOrder);
    };

    const saveStatusOnOrder = () => {
        setError(false);
        let validationError = false;
        let tempOrder = {
            pickupDate: order.pickupDate,
        };

        if (!tempOrder.pickupDate) {
            setError(true);
            validationError = true;
        }

        if (validationError) return;

        updatePickUpDate({
            variables: {
                contentItemId: order.contentItemId,
                pickupDate: order.pickupDate,
            },
        }).then((resp) => {
            if (resp.data.updateReturnOrderFinalPickupDate) {
                refetch();
                setOpen(false);
                snackBarApi.showSnackBar("Pickup date saved", "success");
            } else {
                snackBarApi.showSnackBar("Pickup niet opgeslagen", "error");
            }
        });
    };

    const disableDates = (date: MaterialUiPickersDate) => {
        if (!date) return false;

        const dates = t("notSelectableDates");
        const formatDates = dates.map((d) => new Date(d).toDateString());

        if (formatDates.includes(date.toDateString())) {
            return true;
        } else if (date?.getDay() === 0 || date?.getDay() === 6) {
            return true;
        } else {
            return false;
        }
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <PopUp
            open={open}
            onClose={onClose}
            content={() => (
                <>
                    <Box pl={4} pr={4} pt={2} pb={2}>
                        <RenderTitle element="h1" text={t("returnDialog.article")} line={true} />
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">{returnOrder.returnOrderId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2">
                                    {returnOrder.pickupDate && convertDate(returnOrder.pickupDate)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />

                    {returnOrder.lines.map((returnOrderLine: ReturnOrderLineType) => (
                        <DialogOrderLine
                            key={returnOrderLine.contentItemId}
                            orderId={returnOrder.orderId}
                            returnOrderLine={returnOrderLine}
                        />
                    ))}

                    {permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) && (
                        <Box className={classes.dateWrap}>
                            <Grid item xs={6}>
                                <div className={classes.addressWrap}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                        <InputLabel shrink={false} htmlFor="selectedDate">
                                            {t("returnDialog.pickupDate")}
                                        </InputLabel>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="selectedDate"
                                            minDate={minDate}
                                            shouldDisableDate={disableDates}
                                            value={formatDate}
                                            onChange={(date: MaterialUiPickersDate) =>
                                                handleOrderChange(date, "pickupDate")
                                            }
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            className={classes.datePicker}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        </Box>
                    )}
                </>
            )}
            buttons={() => (
                <>
                    {error && (
                        <Box pl={4}>
                            <Typography variant="body2" color="error">
                                {t("returnDialog.validation")}
                            </Typography>
                        </Box>
                    )}

                    <Button onClick={() => setOpen(false)} color="primary">
                        {t("returnDialog.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!order.pickupDate || loading}
                        onClick={saveStatusOnOrder}
                        autoFocus
                        color="primary"
                        endIcon={!loading ? <ArrowRightIcon /> : <CircularProgress color="inherit" size="1em" />}
                    >
                        {t("returnDialog.sendDate")}
                    </Button>
                </>
            )}
        />
    );
};

export default ReturnDialog;
