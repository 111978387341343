import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    makeStyles,
    Theme,
    Grid,
    Button,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";

import { convertDate } from "helpers";
import { useAppSelector } from "hooks";
import { ReturnOrderType, ReturnOrderLineType } from "types";
import { DECLINE_RETURN_REASON, USER_PERMISSIONS } from "const";

import ReturnOrderLine from "./ReturnOrderLine";
import ReturnDialog from "./ReturnDialog";
import DateDialog from "./DateDialog";
import useReturnOrder from "../useReturnOrder";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(0, 4),
        minHeight: "auto",

        "&.Mui-expanded": {
            minHeight: "auto",
        },
    },
    content: {
        "&.Mui-expanded": {
            margin: "12px 0",
        },
    },
    accordionRoot: {
        flexDirection: "column",
        padding: "10px 0",
    },
    paper: {
        backgroundColor: "transparent",
    },
    button: {
        color: theme.palette.common.white,
        padding: 0,
    },
    dialog: {
        width: 770,
        minWidth: 770,
        backgroundColor: theme.palette.background.default,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        marginRight: 240,
    },
    dialogPaper: {
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "70px",
    },
    editButton: {
        padding: 0,
    },
    downloadButton: {
        padding: 0,
        textDecoration: "none",
        color: theme.palette.common.white,
        fontSize: "0.875rem",
        fontWeight: 300,
        display: "flex",
        alignItems: "center",
    },
}));

type Props = {
    returnOrder: ReturnOrderType;
    refetch: any;
};

const ReturnOrder: React.FC<Props> = ({ returnOrder, refetch }) => {
    const { t } = useTranslation();
    const { api } = useReturnOrder();
    const permissions = useAppSelector((state) => state.auth.user.permissions);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDate, setOpenDate] = useState(false);

    const openReturnDialog = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FocusEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        const lines = [...returnOrder.lines];

        const newLines = lines.map((line) => ({
            reason: DECLINE_RETURN_REASON.EMPTY,
            status: line.status,
            contentItemId: line.contentItemId,
        }));

        api.setOrder({
            contentItemId: returnOrder.contentItemId,
            reason: returnOrder.reason || DECLINE_RETURN_REASON.EMPTY,
            status: returnOrder.status,
            lines: newLines,
        });

        setOpenDialog(true);
    };

    const openDateDialog = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FocusEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        api.setOrder({
            contentItemId: returnOrder.contentItemId,
        });

        setOpenDate(true);
    };

    const classes = useStyles();
    return (
        <Fragment>
            <Accordion
                classes={{
                    root: classes.paper,
                }}
                elevation={0}
                defaultExpanded={true}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon color="inherit" />}
                    classes={{
                        root: classes.root,
                        content: classes.content,
                        expandIcon: classes.button,
                    }}
                >
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">{returnOrder.returnOrderId}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">
                                {returnOrder.createdOn && convertDate(returnOrder.createdOn)}
                            </Typography>
                        </Grid>
                        {!permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) && (
                            <Grid item xs={2}>
                                <Typography variant="subtitle2">
                                    {t(`returnMethod.${returnOrder.returnMethod}`)}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                {/* // @ts-ignore */}
                                {t(`orderStatus.${returnOrder.status}`)}
                            </Typography>
                        </Grid>
                        {(permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders) ||
                            permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) ||
                            permissions.includes(USER_PERMISSIONS.Nijburg_CanImpersonate)) &&
                            returnOrder.confirmationDocument && (
                                <Grid item xs={3}>
                                    <Button
                                        href={returnOrder.confirmationDocument ? returnOrder.confirmationDocument : ""}
                                        target="_blank"
                                        rel="noreferrer"
                                        onFocus={(event) => event.stopPropagation()}
                                        onClick={(event) => event.stopPropagation()}
                                        className={classes.downloadButton}
                                        color="inherit"
                                        endIcon={<GetAppIcon fontSize="small" />}
                                    >
                                        {t("downloadConfirmationDocument")}
                                    </Button>
                                </Grid>
                            )}
                        {permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) && (
                            <Grid item xs={3}>
                                <Button
                                    className={classes.editButton}
                                    onClick={(event) => openReturnDialog(event)}
                                    onFocus={(event) => event.stopPropagation()}
                                    color="inherit"
                                    endIcon={<MoreVertIcon fontSize="small" />}
                                >
                                    {t("changeStatus")}
                                </Button>
                            </Grid>
                        )}
                        {permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) && (
                            <Grid item xs={3}>
                                <Button
                                    className={classes.editButton}
                                    onClick={(event) => openDateDialog(event)}
                                    onFocus={(event) => event.stopPropagation}
                                    color="inherit"
                                    endIcon={<MoreVertIcon fontSize="small" />}
                                >
                                    {t("returnOrder.finalPickUpDate")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    classes={{
                        root: classes.accordionRoot,
                    }}
                >
                    {returnOrder.lines.map((returnOrderLine: ReturnOrderLineType) => (
                        <ReturnOrderLine
                            key={returnOrderLine.contentItemId}
                            returnOrderLine={returnOrderLine}
                            returnOrder={returnOrder}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
            {openDialog && (
                <ReturnDialog open={openDialog} setOpen={setOpenDialog} refetch={refetch} returnOrder={returnOrder} />
            )}
            {openDate && (
                <DateDialog open={openDate} setOpen={setOpenDate} refetch={refetch} returnOrder={returnOrder} />
            )}
        </Fragment>
    );
};

export default ReturnOrder;
