import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
    Container,
    Grid,
    Typography,
    makeStyles,
    Theme,
    Box,
    Divider,
    Button,
    CircularProgress,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { USER_ROLES } from "const";
import { validateEmail } from "helpers";
import { useAppSelector, useSnackBar } from "hooks";
import { Loader, PopUp, RenderTitle } from "shared";

import { FORGOT_PASSWORD } from "api/Mutations";
import { GET_CURRENT_USER } from "api/Queries";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(6, 0),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
        width: "100%",
    },
    contactBlock: {
        backgroundColor: theme.palette.common.white,
        borderRadius: `0 0 0 10px`,
        marginTop: theme.spacing(4),
    },
    dialog: {
        width: 770,
        minWidth: 770,
        backgroundColor: theme.palette.background.default,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        marginRight: 240,
    },
    dialogPaper: {
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "70px",
    },
}));

const UserInfo = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { snackBarApi } = useSnackBar();
    const { data, loading } = useQuery(GET_CURRENT_USER);
    const [forgotPassword] = useMutation(FORGOT_PASSWORD);
    const currentUser = useAppSelector((state) => state.auth.user);

    const [open, setOpen] = useState(false);

    const userRole = currentUser.role;
    const user = (data && data.currentUser) || {};
    const customer = (data && data.currentUser && data.currentUser.customer) || {};

    const handleSubmit = () => {
        if (!validateEmail(user.user.email)) {
            snackBarApi.showSnackBar(t("account.resetPassword.emailFailed"), "error");
            return;
        }
        forgotPassword({
            variables: {
                email: user.user.email,
            },
        }).then((resp) => {
            if (resp.data.forgotPassword) {
                snackBarApi.showSnackBar(t("account.resetPassword.emailSuccess"), "success");
                setOpen(false);
            }
        });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            {user && (
                <div className={classes.wrap}>
                    <Typography variant="h1">Account</Typography>
                    <Typography variant="body1">
                        Bekijk al uw persoonlijke gegevens hieronder.
                        <br />
                        U kunt hier ook gemakkelijk uw wachtwoord wijzigen.
                    </Typography>
                    {loading && (
                        <div>
                            <Loader />
                        </div>
                    )}
                    {user && customer && !loading && (
                        <Grid container direction="column" className={classes.contactBlock}>
                            <Box p={4} pb={0}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            <strong>
                                                {user.firstName} {user.middleName} {user.lastName}
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1">
                                            <strong>{customer.name}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1">{customer.customerId}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            {user && user.user && user.user.email && (
                                <Box p={4}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {user.user.email}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button color="inherit" onClick={() => setOpen(true)}>
                                                <Typography variant="body1">
                                                    <strong>{t("account.changePassword")}</strong>
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            <Divider />
                            {userRole === USER_ROLES.Nijburg_Customer && (
                                <Box p={4}>
                                    <RenderTitle element="h2" text="gegevens gebruiker" line={true} />
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >
                                        <Grid item xs={4}>
                                            <Grid container direction="column" alignItems={"flex-start"}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{t("deliveryAddress")}</strong>
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    {customer.postalAddress.street}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {customer.postalAddress.postalCode} {customer.postalAddress.city}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container direction="column" alignItems={"flex-start"}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{t("billingAddress")}</strong>
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    {customer.postalAddress.street}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {customer.postalAddress.postalCode} {customer.postalAddress.city}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container direction="column" alignItems={"flex-start"}>
                                                <Typography variant="body2" gutterBottom>
                                                    <strong>{t("district")}</strong>
                                                </Typography>
                                                <Typography variant="body2">{customer.area}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {user && user.user && user.user.phoneNumer && (
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Grid item xs={4} alignItems="flex-start">
                                                    <Typography variant="body2">
                                                        <strong>{t("phoneNumber")}</strong>
                                                    </Typography>
                                                    <br />
                                                    <Typography variant="body2">{user.user.phoneNumber}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            )}
                        </Grid>
                    )}
                    {open && (
                        <PopUp
                            open={open}
                            onClose={() => setOpen(false)}
                            content={() => (
                                <>
                                    <Box p={4}>
                                        <Typography variant="h2" color="inherit">
                                            {t("account.changePassword")}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box p={4}>
                                        <Typography variant="body1" color="inherit">
                                            {t("account.changePasswordText")}
                                        </Typography>
                                        <Typography variant="body1" color="inherit" display={"inline"}>
                                            {t("account.changePasswordDescription")} <strong>{user.user.email}</strong>
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            buttons={() => (
                                <>
                                    <Button onClick={() => setOpen(false)} color="primary">
                                        {t("cancel")}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        autoFocus
                                        color="primary"
                                        endIcon={
                                            !loading ? (
                                                <ArrowRightIcon />
                                            ) : (
                                                <CircularProgress color="inherit" size="1em" />
                                            )
                                        }
                                    >
                                        {t("send")}
                                    </Button>
                                </>
                            )}
                        />
                    )}
                </div>
            )}
        </Container>
    );
};

export default UserInfo;
