import { Token } from "types";
import jwt_decode from "jwt-decode";

const getUserFromToken = (token: string) => {
    let decoded: Token = jwt_decode(token);
    let allowedCustomers = decoded.NijburgAllowedCustomers ? JSON.parse(decoded.NijburgAllowedCustomers) : "";
    let email = decoded.email || "";
    let name = decoded.name;
    let firstName = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"] || "";
    let lastName = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"] || "";
    let role = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || "";
    let permissions = decoded.Permission || [];
    let impersonating = decoded.Impersonating;
    let impersonator = decoded.Impersonator;

    return { name, firstName, lastName, email, role, permissions, allowedCustomers, impersonating, impersonator };
};

export default getUserFromToken;
