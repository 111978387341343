import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertDate } from "helpers";
import { useAppSelector, useAppDispatch, useSnackBar } from "hooks";
import { ReturnOrderType, ReturnOrderLineType } from "types";
import { RETURN_METHOD, RETURN_ORDER_STATUS, RETURN_REASON } from "const";

import { Box, Button, Typography, Grid, Divider } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { addReturnOrders } from "store/slices/OrderSlice";
import { CustomNumberInput, ReturnReasonSelector, RenderTitle, PopUp, CustomFileUpload } from "shared";

type Props = {
    open: boolean;
    onClose: () => void;
};

const ReturnDialog: React.FC<Props> = ({ onClose, open }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { snackBarApi } = useSnackBar();
    const order = useAppSelector((state) => state.order.currentOrder);
    const currentArticle = useAppSelector((state) => state.order.currentArticle);
    const storeReturnOrders = useAppSelector((state) => state.order.returnOrders);

    const currentArticleId = currentArticle?.contentItemId || "";

    const [returnError, setReturnError] = useState(false);
    const [returnSelectError, setReturnSelectError] = useState(false);
    const [returnNumber, setReturnNumber] = useState<number>(0);
    const [tempFiles, setTempFiles] = useState<FileList>();
    const [returnSelect, setReturnSelect] = useState<typeof RETURN_REASON[keyof typeof RETURN_REASON]>(
        RETURN_REASON.EMPTY
    );

    const [tempOrder, setTempOrder] = useState<ReturnOrderType>({
        street: order?.street || "",
        city: order?.city || "",
        postalCode: order?.postalCode || "",
        status: RETURN_ORDER_STATUS.PENDING,
        shippedOn: order?.shippedOn || new Date(),
        lines: [],
        phoneNumber: order?.phoneNumber || "",
        projectName: order?.projectName || "",
        contactPerson: order?.contactPerson || "",
        returnMethod: RETURN_METHOD.DELIVER,
        purchaseNumber: order?.purchaseNumber || "",
        orderId: order?.orderId || "",
        pickupDate: order?.pickupDate || new Date(),
        customerReference: order?.customerReference || "",
        customerId: order?.customerId || "",
        contentItemId: order?.contentItemId || "",
        returnOrderId: order?.returnOrderId || "",
        readyToSend: false,
        tempAddress: {
            street: order?.street || "",
            city: order?.city || "",
            postalCode: order?.postalCode || "",
        },
    });

    const [tempArticle, setTempArticle] = useState<ReturnOrderLineType>({
        returnCount: currentArticle?.returnCount || 0,
        reason: RETURN_REASON.EMPTY,
        allowedReturnQuantity: currentArticle?.allowedReturnQuantity || 0,
        status: RETURN_ORDER_STATUS.PENDING,
        contentItemId: currentArticle?.contentItemId || "",
        shippedOn: new Date(),
        articleNumber: currentArticle?.articleNumber || "",
        orderId: currentArticle?.orderId || "",
        quantity: currentArticle?.quantity || 0,
        productDescription: currentArticle?.productDescription || "",
        productName: currentArticle?.productName || "",
        returnOrderId: currentArticle?.returnOrderId || "",
    });

    let stateOrder = storeReturnOrders.find(
        (returnOrder: ReturnOrderType) => returnOrder.contentItemId === order?.contentItemId
    );

    useEffect(() => {
        if (stateOrder) {
            // if order is in the state set tempOrder
            setTempOrder(stateOrder);

            let articleIndex = stateOrder.lines.findIndex((line) => line.contentItemId === currentArticleId);
            let orderArticleIndex = order?.lines.findIndex((line) => line.contentItemId === currentArticleId) || -1;

            // if article not in state copy use the line from the order
            if (articleIndex >= 0) {
                setTempArticle((temp) => ({
                    ...temp,
                    ...stateOrder?.lines[articleIndex],
                }));
            }
            if (orderArticleIndex >= 0) {
                setTempArticle((temp) => ({
                    ...temp,
                    ...order?.lines[orderArticleIndex],
                }));
            }

            if (stateOrder?.lines[articleIndex]) {
                setReturnNumber(stateOrder?.lines[articleIndex].returnCount);
                setReturnSelect(stateOrder?.lines[articleIndex].reason);
                setTempFiles(stateOrder?.lines[articleIndex].tempFiles);
            }
        } else if (order) {
            setTempOrder((order) => ({
                ...order,
            }));

            let article = order.lines.find((line) => line.contentItemId === currentArticleId);
            setTempArticle((temp) => ({
                ...temp,
                ...article,
            }));
        }
    }, [order, currentArticleId, currentArticle, stateOrder]);

    const saveOrderToState = () => {
        setReturnError(false);
        setReturnSelectError(false);
        if (returnNumber <= 0) {
            setReturnError(true);
        }
        if (returnSelect === RETURN_REASON.EMPTY) {
            setReturnSelectError(true);
        }
        if (returnNumber <= 0 || returnSelect === RETURN_REASON.EMPTY) return;

        let tempOrders: ReturnOrderType[] = [...storeReturnOrders];
        let tempOrderIndex = tempOrders.findIndex((order) => order.contentItemId === tempOrder.contentItemId);

        if (tempOrderIndex < 0) {
            let newOrder = {
                ...tempOrder,
                lines: [
                    {
                        ...tempArticle,
                        reason: returnSelect,
                        returnCount: returnNumber,
                        tempFiles: tempFiles,
                    },
                ],
            };

            tempOrders = [...tempOrders, newOrder];
        } else {
            let tempOrder = { ...tempOrders[tempOrderIndex] };

            if (!tempOrder) return;
            let tempLineIndex = tempOrder.lines.findIndex((line) => line.contentItemId === tempArticle.contentItemId);

            if (tempLineIndex < 0) {
                tempOrder.lines = [
                    ...tempOrder.lines,
                    {
                        ...tempArticle,
                        reason: returnSelect,
                        returnCount: returnNumber,
                        tempFiles: tempFiles,
                    },
                ];
            } else {
                const lines = [...tempOrder.lines];
                lines[tempLineIndex] = {
                    ...lines[tempLineIndex],
                    returnCount: returnNumber,
                    reason: returnSelect,
                    tempFiles: tempFiles,
                };
                tempOrder.lines = lines;
            }
            tempOrders[tempOrderIndex] = {
                ...tempOrders[tempOrderIndex],
                ...tempOrder,
            };
        }

        dispatch(addReturnOrders(tempOrders));
        snackBarApi.showSnackBar("Orderline aan retour toegevoegd", "success");
        onClose();
    };

    const handleNumberChange = (value: number) => {
        const val = value;
        setReturnNumber(val);
    };

    const handleReasonChange = (value: typeof RETURN_REASON[keyof typeof RETURN_REASON]) => {
        setReturnSelect(value);
    };

    const handleFilesChange = (files: FileList) => {
        setTempFiles(files);
    };

    return (
        <PopUp
            open={open}
            onClose={onClose}
            content={() => (
                <>
                    <Box pl={4} pr={4} pt={4} pb={2}>
                        <RenderTitle element="h3" text={t("returnDialog.title")} />
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">{order?.orderId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2">{convertDate(order?.shippedOn)}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2">{order?.projectName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle2">{order?.customerId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2"></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2">{order?.customerReference}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    {tempArticle && (
                        <Box pl={4} pr={4} pt={2} pb={2}>
                            <RenderTitle element="h2" text={t("returnDialog.article")} line={true} />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={2}>
                                    <Typography variant="subtitle1">{tempArticle?.articleNumber}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2">{tempArticle?.allowedReturnQuantity}x</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{tempArticle?.productDescription}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Divider />

                    <Box p={4}>
                        <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <CustomNumberInput
                                    error={returnError}
                                    value={returnNumber}
                                    maxValue={tempArticle.allowedReturnQuantity}
                                    onChange={handleNumberChange}
                                    label={t("amountLabel")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <ReturnReasonSelector
                                    error={returnSelectError}
                                    id="returnReason"
                                    value={returnSelect}
                                    onChange={handleReasonChange}
                                    label={t("returnReasonLabel")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomFileUpload
                                    label={t("documents")}
                                    value={tempFiles}
                                    onChange={handleFilesChange}
                                    id="documents"
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box pl={4}>
                        <Grid container direction="row" spacing={0} justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={3}>
                                {returnError && (
                                    <Typography variant="body2" color="error">
                                        {t("returnDialog.returnCountError")}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                {returnSelectError && (
                                    <Typography variant="body2" color="error">
                                        {t("returnDialog.chooseReasonError")}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            buttons={() => (
                <>
                    <Button onClick={() => onClose()} color="primary">
                        {t("returnDialog.cancel")}
                    </Button>
                    <Button
                        onClick={saveOrderToState}
                        variant="contained"
                        autoFocus
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="large" />}
                    >
                        {t("returnDialog.return")}
                    </Button>
                </>
            )}
        ></PopUp>
    );
};

export default ReturnDialog;
