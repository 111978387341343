import { useEffect, useState } from "react";
import clsx from "clsx";

import { makeStyles, Theme, Grid, Typography, Checkbox } from "@material-ui/core";

import { useAppSelector } from "hooks";
import { renderMaxTextLength } from "helpers";
import { OrderLineType, ReturnOrderType, ReturnOrderLineType } from "types";

const useStyles = makeStyles((theme: Theme) => ({
    article: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        transition: "all 250ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,

            "& $articleId": {
                color: theme.palette.primary.main,
            },
        },
    },
    articleId: {},
    disabled: {
        pointerEvents: "none",
        opacity: 0.54,
    },
    selected: {
        "& $articleId": {
            color: theme.palette.primary.main,
        },
    },
    noLineWrap: {
        whiteSpace: "normal",
    },
}));

type Props = {
    orderLine: OrderLineType;
    openPopup: (returnOrder: OrderLineType) => void;
};

const OrderLine: React.FC<Props> = ({ orderLine, openPopup }) => {
    const classes = useStyles();
    const storeReturnOrders = useAppSelector((state) => state.order.returnOrders);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        let stateOrder = storeReturnOrders.find(
            (returnOrder: ReturnOrderType) => returnOrder.orderId === orderLine.orderId
        );
        let stateArticle = stateOrder?.lines.find(
            (returnOrderLine: ReturnOrderLineType) => returnOrderLine.contentItemId === orderLine?.contentItemId
        );

        if (!stateArticle) {
            setSelected(false);
            return;
        }
        stateArticle && stateArticle.returnCount && stateArticle?.returnCount > 0
            ? setSelected(true)
            : setSelected(false);
    }, [storeReturnOrders, orderLine]);

    return (
        <Grid
            className={clsx(
                classes.article,
                (!orderLine.isReturnable || orderLine.allowedReturnQuantity <= 0) && classes.disabled
            )}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            onClick={() => openPopup(orderLine)}
        >
            <Grid item xs={1}>
                {orderLine.isReturnable && orderLine.allowedReturnQuantity > 0 && (
                    <Checkbox checked={selected} size="small" color="primary" />
                )}
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.articleId} variant="subtitle1">
                    {orderLine.articleNumber}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle2">
                    {orderLine.isReturnable ? orderLine.allowedReturnQuantity : orderLine.quantity}x
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography className={classes.noLineWrap} variant="subtitle2">
                    {renderMaxTextLength(orderLine.productDescription, 70, true)}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default OrderLine;
