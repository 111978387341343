import React from "react";
import ReturnOrderContext from "./ReturnOrderContext";

function useReturnOrder() {
    const context = React.useContext(ReturnOrderContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a CountProvider");
    }
    return context;
}

export default useReturnOrder;
