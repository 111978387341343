import "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/nl";

import {
    Grid,
    Theme,
    Typography,
    makeStyles,
    Paper,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputLabel,
} from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import { useOrder } from "hooks";
import { RETURN_METHOD } from "const";
import { convertDate, validatePhoneNumber } from "helpers";
import { ReturnOrderLineType, ReturnOrderType } from "types";
import { RenderTitle, CustomInput, ReturnTypeSelector } from "shared";

import ReturnOrderLine from "./ReturnOrderLine";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        justifyContent: "flex-start",
    },
    paper: {
        padding: theme.spacing(4),
        borderRadius: "0 5px 0 5px",
        boxShadow: "0 5px 20px 0 rgba(87,86,86,0.15)",
        marginBottom: theme.spacing(8),
    },
    datePicker: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ced5d8",
        height: 40,
        padding: theme.spacing(1, 0, 1, 2),
        marginBottom: 0,
        marginTop: theme.spacing(2),
    },
    addressWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(4),
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(2, 4),
        marginBottom: theme.spacing(2),
    },
    checked: {
        color: theme.palette.primary.main,
        "&.Mui-checked": {
            color: theme.palette.primary.main,
        },
    },
    deliverAddress: {
        margin: theme.spacing(4, 0),
    },
    flex: {
        display: "flex",
        flexDirection: "column",
    },
}));

type Props = {
    returnOrder: ReturnOrderType;
    error: boolean;
};

const ReturnOrder: React.FC<Props> = ({ returnOrder, error }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { changeValueOnOrder, changeAddressOnOrder } = useOrder();

    const [deliveryAddressChoice, setDeliveryAddressChoice] = useState("default");
    const [formatDate, setFormatDate] = useState(returnOrder?.pickupDate);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    if (locale && locale.options) {
        if (i18n.language === "nl") {
            locale.options.weekStartsOn = 1;
        }
    }

    useEffect(() => {
        const orderDate = returnOrder.pickupDate;
        const today = new Date();

        if (!orderDate || orderDate <= today) {
            today.setDate(today.getDate() + 1);
            setFormatDate(today);
        } else {
            setFormatDate(orderDate);
        }
    }, [returnOrder]);

    useEffect(() => {
        if (deliveryAddressChoice === "default") {
            // TODO: Change to default order address
            changeAddressOnOrder(
                {
                    street: returnOrder.tempAddress.street,
                    postalCode: returnOrder.tempAddress.postalCode,
                    city: returnOrder.tempAddress.city,
                },
                returnOrder.contentItemId
            );
        } else {
            changeAddressOnOrder(
                {
                    street: "",
                    postalCode: "",
                    city: "",
                },
                returnOrder.contentItemId
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryAddressChoice]);

    const handleStateChange = (key: string, value: string | number | MaterialUiPickersDate, orderId: string) => {
        changeValueOnOrder(key, value, orderId);
    };

    const disableDates = (date: MaterialUiPickersDate) => {
        if (!date) return false;

        const dates = t("notSelectableDates");
        const formatDates = dates.map((d) => new Date(d).toDateString());

        if (formatDates.includes(date.toDateString())) {
            return true;
        } else if (date?.getDay() === 0 || date?.getDay() === 6) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <header className={classes.header}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">{returnOrder.orderId}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2">
                            {returnOrder.shippedOn && convertDate(returnOrder.shippedOn)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{returnOrder.projectName}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={3}>
                        <Typography variant="subtitle2">{returnOrder.customerId}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2"></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{returnOrder.customerReference}</Typography>
                    </Grid>
                </Grid>
            </header>

            {returnOrder.lines.map((orderLine: ReturnOrderLineType) => (
                <ReturnOrderLine
                    key={orderLine.contentItemId}
                    orderId={returnOrder.contentItemId}
                    orderLine={orderLine}
                />
            ))}

            <Paper className={classes.paper}>
                <RenderTitle element="h2" text={t("returns.formTitle")} line={false} />
                <Grid container direction="row" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={6}>
                        <ReturnTypeSelector
                            onChange={(val) => handleStateChange("returnMethod", val, returnOrder.contentItemId)}
                            id="returnMethod"
                            label={t("returns.shippingMethod")}
                            value={returnOrder.returnMethod}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {returnOrder.returnMethod === RETURN_METHOD.PICKUP && (
                            <div className={classes.addressWrap}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                    <InputLabel shrink={false} htmlFor="selectedDate">
                                        {t("returns.shippingDate")}
                                    </InputLabel>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="selectedDate"
                                        shouldDisableDate={disableDates}
                                        minDate={minDate}
                                        value={formatDate}
                                        onChange={(date: MaterialUiPickersDate) =>
                                            handleStateChange("pickupDate", date, returnOrder.contentItemId)
                                        }
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        className={classes.datePicker}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                    </Grid>
                </Grid>
                {returnOrder.returnMethod === RETURN_METHOD.PICKUP && (
                    <Grid container direction="row" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={4}>
                            <Grid
                                container
                                direction="row"
                                spacing={4}
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12}>
                                    <RadioGroup
                                        aria-label="delivery address"
                                        name="deliveryAddress1"
                                        value={deliveryAddressChoice}
                                        onChange={(e) => setDeliveryAddressChoice(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="default"
                                            control={
                                                <Radio
                                                    classes={{
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            }
                                            label={t("returns.defaultDeliveryAddress")}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        {returnOrder.tempAddress.street}
                                        <br /> {returnOrder.tempAddress.postalCode} {returnOrder.tempAddress.city}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid
                                container
                                direction="row"
                                spacing={4}
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12}>
                                    <RadioGroup
                                        aria-label="delivery address"
                                        name="deliveryAddress1"
                                        value={deliveryAddressChoice}
                                        onChange={(e) => setDeliveryAddressChoice(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="different"
                                            control={
                                                <Radio
                                                    classes={{
                                                        checked: classes.checked,
                                                    }}
                                                />
                                            }
                                            label={t("returns.differentDeliveryAddress")}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInput
                                        id="street"
                                        value={returnOrder.street}
                                        placeholder={t("returns.streetName")}
                                        type="text"
                                        error={!returnOrder?.street}
                                        onChange={(value) =>
                                            handleStateChange("street", value, returnOrder.contentItemId)
                                        }
                                        disabled={deliveryAddressChoice === "default"}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <CustomInput
                                        id="postalCode"
                                        value={returnOrder.postalCode}
                                        error={!returnOrder?.postalCode}
                                        placeholder={t("returns.zipCode")}
                                        type="text"
                                        onChange={(value) =>
                                            handleStateChange("postalCode", value, returnOrder.contentItemId)
                                        }
                                        disabled={deliveryAddressChoice === "default"}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <CustomInput
                                        id="city"
                                        value={returnOrder.city}
                                        error={!returnOrder?.city}
                                        placeholder={t("returns.city")}
                                        type="text"
                                        onChange={(value) =>
                                            handleStateChange("city", value, returnOrder.contentItemId)
                                        }
                                        disabled={deliveryAddressChoice === "default"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {returnOrder.returnMethod === RETURN_METHOD.DELIVER && (
                    <Grid container direction="column" spacing={2} className={classes.deliverAddress}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Magazijn 20</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t("returns.deliverAddress")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{t("returns.deliverText")}</Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container direction="row" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={4}>
                        <CustomInput
                            id="contactPerson"
                            label={t("returns.contactPerson")}
                            error={!returnOrder?.contactPerson}
                            value={returnOrder.contactPerson || ""}
                            type="text"
                            onChange={(value) => handleStateChange("contactPerson", value, returnOrder.contentItemId)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.flex}>
                            <CustomInput
                                id="phoneNumber"
                                label={t("returns.phoneNumber")}
                                value={returnOrder.phoneNumber || ""}
                                error={!validatePhoneNumber(returnOrder.phoneNumber) || !returnOrder?.phoneNumber}
                                type="phone"
                                onChange={(value) => handleStateChange("phoneNumber", value, returnOrder.contentItemId)}
                            />

                            {!validatePhoneNumber(returnOrder.phoneNumber) && (
                                <Typography variant="body2" color="error">
                                    {t("returnDialog.phoneError")}
                                </Typography>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {error && (
                    <Typography variant="subtitle1" color="error">
                        {t("returns.fieldValidationError")}
                    </Typography>
                )}
            </Paper>
        </React.Fragment>
    );
};

export default ReturnOrder;
