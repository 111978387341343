import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { Container, Typography, makeStyles, Theme } from "@material-ui/core";

import { useAppSelector, useSnackBar } from "hooks";
import { AllowedCustomer } from "types";
import { CustomButton, RenderTitle, CustomSelector } from "shared";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(6, 0),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    formRoot: {
        width: 680,
        margin: theme.spacing(4, 0),
    },
    select: {
        width: "100%",
        marginBottom: "20px",
    },
}));

const CustomerSwitch = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { snackBarApi } = useSnackBar();
    const customers = useAppSelector((state) => state.auth.user.allowedCustomers);

    let sortedCustomers = customers ? [...customers] : [];
    sortedCustomers.sort((a: AllowedCustomer, b: AllowedCustomer) => {
        let aNum = parseInt(a.CustomerId.substring(1));
        let bNum = parseInt(b.CustomerId.substring(1));

        if (aNum < bNum) {
            return -1;
        }
        if (aNum > bNum) {
            return 1;
        }
        return 0;
    });

    const customerNames = sortedCustomers
        ? sortedCustomers.map((customer: AllowedCustomer) => `${customer.CustomerId} - ${customer.Name}`)
        : [];
    const client = Cookies.get("client-nijburg");
    const currentCustomer = sortedCustomers?.find((customer: AllowedCustomer) => customer.CustomerId === client);
    const [location, setLocation] = useState(`${currentCustomer?.CustomerId} - ${currentCustomer?.Name}` || "empty");

    const changeLocation = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown | string;
        }>
    ) => {
        let value = e.target.value;
        if (typeof value === "string") {
            setLocation(value);
        }
    };

    const switchLocation = () => {
        let id = location.split(" - ")[0].trimStart();

        let customer = sortedCustomers?.find(
            (customer: AllowedCustomer) => customer.CustomerId.toLowerCase() === id.toLowerCase()
        );

        if (customer) {
            Cookies.set("client-nijburg", customer.CustomerId, { path: "/" });
            history.push("/orders");
            snackBarApi.showSnackBar(t("customerSwitch.success"), "success");
        } else {
            snackBarApi.showSnackBar(t("customerSwitch.error"), "error");
        }
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <RenderTitle element="h2" text={t("customerSwitch.title")} line={true} />
                <Typography variant="body1">{t("customerSwitch.introText")}</Typography>
                <CustomSelector
                    value={location}
                    id="location"
                    options={customerNames}
                    handleChange={changeLocation}
                    className={classes.select}
                />
                <CustomButton
                    color="primary"
                    variant="contained"
                    text={t("customerSwitch.button")}
                    onClick={switchLocation}
                />
            </div>
        </Container>
    );
};

export default CustomerSwitch;
