import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "locales";
import { store } from "store";
import theme from "theme/Theme";
import apolloClient from "api";

import App from "components/App";
import SnackBarProvider from "shared/SnackBar/SnackBarProvider";

// Root component to init all app providers, base styling and translations
const Root: React.FC = () => {
    return (
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <ThemeProvider theme={theme}>
                    <SnackBarProvider>
                        <Router>
                            <CssBaseline />
                            <Suspense fallback="loading">
                                <App />
                            </Suspense>
                        </Router>
                    </SnackBarProvider>
                </ThemeProvider>
            </ApolloProvider>
        </Provider>
    );
};

export default Root;
