import ClientOAuth2 from "client-oauth2";
import { useAppDispatch } from "hooks";
import { login, logout, setUser } from "store/slices/AuthSlice";
import { useHistory } from "react-router";
import Cookies from "js-cookie";
import { getUserFromToken } from "helpers";

const AuthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    accessTokenUri: `${process.env.REACT_APP_API_URL}connect/token`,
    authorizationUri: `${process.env.REACT_APP_API_URL}connect/authorize`,
    redirectUri: `${process.env.REACT_APP_PORTAL_URL}auth/singin-callback`,
    scopes: ["api"],
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
});

const AuthService = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const userLogin = (username: string, password: string) => {
        return AuthClient.owner
            .getToken(username, password)
            .then((resp) => {
                const token = resp.accessToken;
                Cookies.set("jwt-nijburg", token, { path: "/" });
                dispatch(login());
                let permissions;
                let allowedCustomers;
                try {
                    const user = getUserFromToken(token);
                    dispatch(setUser(user));
                    permissions = user.permissions;
                    allowedCustomers = user.allowedCustomers;
                    Cookies.set("client-nijburg", user.allowedCustomers[0].CustomerId, { path: "/" });
                } catch (error) {
                    console.warn("cannot decode token", error);
                }
                return {
                    success: {
                        message: "User loggedin",
                        permissions: permissions,
                        allowedCustomers: allowedCustomers,
                    },
                };
            })
            .catch(() => {
                dispatch(logout());
                Cookies.remove("jwt-nijburg", { path: "/" });
                Cookies.remove("client-nijburg", { path: "/" });
                return { error: "Login failed" };
            });
    };

    const userLogout = () => {
        dispatch(logout());
        Cookies.remove("jwt-nijburg", { path: "/" });
        Cookies.remove("client-nijburg", { path: "/" });
        history.push("/login");
    };

    const userStopImpersonating = () => {
        const oldToken = Cookies.get('jwt-nijburg-old');
        if(oldToken) {
            Cookies.set("jwt-nijburg", oldToken,{ path: "/" });
            const user = getUserFromToken(oldToken);
            dispatch(setUser(user));
            history.push("/impersonate");
            Cookies.remove("jwt-nijburg-old", { path: "/" });
        }
    }

    return {
        userLogin,
        userLogout,
        userStopImpersonating,
    };
};

export default AuthService;
