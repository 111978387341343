import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Divider, Grid, Typography, CircularProgress } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { DECLINE_RETURN_REASON, RETURN_ORDER_STATUS, RETURN_REASON } from "const";
import { convertDate } from "helpers";
import { RenderTitle, StatusSelector, DeclineReasonSelector, PopUp } from "shared";
import { ReturnOrderType, ReturnOrderLineType } from "types";
import DialogOrderLine from "./DialogOrderLine";
import useReturnOrder from "../useReturnOrder";
import { useMutation } from "@apollo/client";
import { UPDATE_RETURN_ORDER_STATUS } from "api/Mutations";

type Props = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    returnOrder: ReturnOrderType;
    refetch: any;
};

const ReturnDialog: React.FC<Props> = ({ open, setOpen, returnOrder, refetch }) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);

    const { order, api } = useReturnOrder();
    const [saveReturnOrder, { loading }] = useMutation(UPDATE_RETURN_ORDER_STATUS);

    const handleOrderChange = (value: RETURN_REASON | DECLINE_RETURN_REASON | RETURN_ORDER_STATUS, name: string) => {
        const tempOrder = { ...order, [name]: value };
        api.setOrder(tempOrder);
    };

    const saveStatusOnOrder = () => {
        setError(false);
        let validationError = false;
        let tempOrder = { ...order };
        let lines = tempOrder.lines ? [...tempOrder.lines] : [];

        if (tempOrder.status !== RETURN_ORDER_STATUS.DECLINED) {
            delete tempOrder.reason;
        }

        if (tempOrder.status === RETURN_ORDER_STATUS.PARTIALLY_APPROVED) {
            lines.map((line) => {
                if (line.status !== RETURN_ORDER_STATUS.DECLINED) {
                    delete line.reason;
                    return line;
                }
                return line;
            });
            tempOrder.lines = lines;
        } else {
            tempOrder.lines = [];
        }

        if (tempOrder.status === RETURN_ORDER_STATUS.DECLINED && tempOrder.reason === RETURN_REASON.EMPTY) {
            setError(true);
            validationError = true;
        }

        tempOrder.lines.forEach((line) => {
            if (line.status === RETURN_ORDER_STATUS.DECLINED && line.reason === RETURN_REASON.EMPTY) {
                setError(true);
                validationError = true;
            }
        });

        if (validationError) return;

        saveReturnOrder({
            variables: {
                ...tempOrder,
            },
        }).then((resp) => {
            if (resp.data.updateReturnOrderStatus) {
                setOpen(false);
                refetch();
            }
        });
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <PopUp
            open={open}
            onClose={onClose}
            content={() => (
                <>
                    <Box pl={4} pr={4} pt={2} pb={2}>
                        <RenderTitle element="h1" text={t("returnDialog.article")} line={true} />
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">{returnOrder.orderId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2">
                                    {returnOrder.pickupDate && convertDate(returnOrder.pickupDate)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box p={4}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Grid item xs={6}>
                                <StatusSelector
                                    onChange={(value) => handleOrderChange(value, "status")}
                                    id="orderStatus"
                                    label={t("statusLabel")}
                                    value={order.status || RETURN_ORDER_STATUS.APPROVED}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                {order.status === RETURN_ORDER_STATUS.DECLINED && (
                                    <DeclineReasonSelector
                                        label={t("returnDialog.returnReason")}
                                        onChange={(value) => handleOrderChange(value, "reason")}
                                        value={order.reason || RETURN_REASON.EMPTY}
                                        id="returnReason"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>

                    {order.status === RETURN_ORDER_STATUS.PARTIALLY_APPROVED &&
                        returnOrder.lines.map((returnOrderLine: ReturnOrderLineType) => (
                            <DialogOrderLine
                                key={returnOrderLine.contentItemId}
                                orderId={returnOrder.orderId}
                                returnOrderLine={returnOrderLine}
                            />
                        ))}
                </>
            )}
            buttons={() => (
                <>
                    {error && (
                        <Box pl={4}>
                            <Typography variant="body2" color="error">
                                {t("returnDialog.validation")}
                            </Typography>
                        </Box>
                    )}
                    <Button onClick={() => setOpen(false)} color="primary">
                        {t("returnDialog.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={order.status === RETURN_ORDER_STATUS.PENDING}
                        onClick={saveStatusOnOrder}
                        autoFocus
                        color="primary"
                        endIcon={!loading ? <ArrowRightIcon /> : <CircularProgress color="inherit" size="1em" />}
                    >
                        {t("returnDialog.send")}
                    </Button>
                </>
            )}
        />
    );
};

export default ReturnDialog;
