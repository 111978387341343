import { FormControl, InputLabel, MenuItem, makeStyles, Theme, Select } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        position: "relative",
        transform: "none",
        marginBottom: theme.spacing(2),
    },
    input: {
        height: "auto",
        padding: theme.spacing(2),
        border: "1px solid #CED5D8",
        "& .MuiInput-input": {
            padding: "0 8px",
            marginTop: theme.spacing(2),
        },
        "& .MuiSelect-root": {
            border: "1px solid black",
        },
        "& .Mui-error": {},
    },
    formRoot: {
        margin: theme.spacing(2, 0),

        "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
        },
    },
    error: {
        "& .MuiSelect-root": {
            border: "1px solid red",
        },
    },
}));

type Props = {
    label?: string;
    options: string[];
    value: string;
    handleChange: (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown | string;
        }>
    ) => void;
    id: string;
    placeholder?: string;
    className: string;
};

const CustomSelector: React.FC<Props> = ({
    id,
    label,
    value,
    options,
    handleChange,
    className,
    placeholder = "Maak een keus",
}) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formRoot} variant="outlined">
            {label && (
                <InputLabel className={classes.label} shrink={false}>
                    {label}
                </InputLabel>
            )}
            <Select
                id={id}
                value={value}
                labelId={id}
                classes={{
                    root: classes.input,
                }}
                onChange={handleChange}
                label={label}
            >
                <MenuItem value="empty">
                    <em>{placeholder}</em>
                </MenuItem>

                {options?.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelector;
