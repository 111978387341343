import { RETURN_METHOD } from "const";
import { validatePhoneNumber } from "helpers";
import { ReturnOrderType } from "types";

const IsOrderValid = (order: ReturnOrderType) => {
    let isValid: boolean = true;

    // contactPerson an email always required
    if (!order.contactPerson || order.contactPerson === "") {
        isValid = false;
    }
    if (!order.phoneNumber || order.phoneNumber === "" || !validatePhoneNumber(order.phoneNumber)) {
        isValid = false;
    }

    // Address only needed when shipment method is pickup
    if (order.returnMethod === RETURN_METHOD.PICKUP) {
        if (!order.street || order.street === "") {
            isValid = false;
        }
        if (!order.city || order.city === "") {
            isValid = false;
        }
        if (!order.postalCode || order.postalCode === "") {
            isValid = false;
        }
    }

    return isValid;
};

export default IsOrderValid;
