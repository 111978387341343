import { gql } from "@apollo/client";

export const SAVE_RETURN_ORDERS = gql`
    mutation ReturnOrders(
        $contactPerson: String!
        $city: String!
        $orderId: String!
        $phoneNumber: String!
        $pickupDate: DateTime!
        $postalCode: String!
        $returnMethod: ReturnMethod!
        $street: String!
        $lines: [ReturnOrderLineInput]!
    ) {
        returnOrders(
            data: {
                contactPerson: $contactPerson
                city: $city
                orderId: $orderId
                phoneNumber: $phoneNumber
                pickupDate: $pickupDate
                postalCode: $postalCode
                returnMethod: $returnMethod
                street: $street
                lines: $lines
            }
        )
    }
`;

export const UPDATE_RETURN_ORDER_STATUS = gql`
    mutation UpdateReturnOrderStatus(
        $contentItemId: String!
        $reason: ReturnOrderStatusReason
        $status: ReturnOrderStatus
        $lines: [UpdateReturnOrderLineStatusInput]
    ) {
        updateReturnOrderStatus(
            data: { contentItemId: $contentItemId, reason: $reason, status: $status, lines: $lines }
        )
    }
`;

export const UPDATE_FINAL_PICKUP_DATE = gql`
    mutation UpdateFinalPickUpDate($contentItemId: String!, $pickupDate: DateTime!) {
        updateReturnOrderFinalPickupDate(data: { contentItemId: $contentItemId, pickupDate: $pickupDate })
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(data: { email: $email })
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!, $newPassword: String!, $resetToken: String!) {
        resetPassword(data: { email: $email, newPassword: $newPassword, resetToken: $resetToken })
    }
`;
