import { Button, CircularProgress } from "@material-ui/core";
import { ElementType, ReactNode } from "react";

type Props = {
    variant?: "text" | "outlined" | "contained";
    color: "inherit" | "primary" | "secondary" | "default";
    type?: "submit";
    text: string;
    icon?: ReactNode;
    loading?: boolean;
    component?: ElementType;
    to?: string;
    disabled?: boolean;
    onClick?: () => void;
};

const CustomButton: React.FC<Props> = ({
    disabled,
    variant,
    type,
    text,
    icon,
    color,
    loading,
    component,
    to,
    onClick,
}) => {
    if (component) {
        return (
            <Button
                component={component}
                type={type}
                to={to}
                variant={variant}
                disabled={disabled}
                color={color}
                onClick={onClick}
                endIcon={!loading ? icon && icon : <CircularProgress color="inherit" size="1em" />}
            >
                {text}
            </Button>
        );
    } else {
        return (
            <Button
                type={type}
                variant={variant}
                color={color}
                disabled={disabled}
                onClick={onClick}
                endIcon={!loading ? icon && icon : <CircularProgress color="inherit" size="1em" />}
            >
                {text}
            </Button>
        );
    }
};

export default CustomButton;
