import { NavLink } from "react-router-dom";
import { makeStyles, Toolbar, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import { USER_PERMISSIONS } from "const";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
    link: {
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
        fontWeight: "bold",
        marginRight: theme.spacing(4),
        letterSpacing: "1.2px",
        lineHeight: "20px",
        textDecoration: "none",
        position: "relative",
    },
    active: {
        color: theme.palette.primary.main,
        "&::after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "2px",
            backgroundColor: theme.palette.primary.main,
            bottom: "-30px",
            left: 0,
        },
    },
}));

const MainMenu = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permissions = useAppSelector((state) => state.auth.user.permissions);

    return (
        <Toolbar className={classes.root} component="nav">
            {permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders) && (
                <NavLink to="/orders" className={classes.link} activeClassName={classes.active} color="inherit" key={1}>
                    {t("menu.returns")}
                </NavLink>
            )}

            {(permissions.includes(USER_PERMISSIONS.Nijburg_ManageOwnReturnOrders) ||
                permissions.includes(USER_PERMISSIONS.Nijburg_ManageLogistics) ||
                permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders)) && (
                <NavLink
                    to="/return-orders"
                    className={classes.link}
                    activeClassName={classes.active}
                    color="inherit"
                    key={2}
                >
                    {t("menu.returnShipment")}
                </NavLink>
            )}
        </Toolbar>
    );
};

export default MainMenu;
