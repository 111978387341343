import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { parse, stringify } from "querystring";

import { InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";

import { DATES } from "const";
import { DateOptions } from "types";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    wrap: {
        display: "flex",
        width: "100%",
    },
    label: {
        marginBottom: theme.spacing(2),
    },
    input: {
        height: "auto",
        padding: 10,
        border: "1px solid #CED5D8",
        width: "100%",
        borderRadius: theme.shape.borderRadius,

        "& .MuiInput-input": {
            padding: "0 8px",
            marginTop: 10,
        },

        "& .MuiSelect-root": {
            border: "1px solid black",
        },
    },
}));

type Props = {
    onChange: (date: DateOptions) => void;
    value: DateOptions;
    id: string;
    label?: string;
};

const DateFilter: React.FC<Props> = ({ onChange, value = "all", id, label = "" }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const handleChange = (date: DateOptions) => {
        const val = date;
        const search = history.location.search.split("?")[1];
        let parsed = parse(search);
        parsed.date = val;

        let newString = stringify(parsed);
        history.push(history.location.pathname + "?" + newString);

        onChange(val);
    };

    return (
        <div className={classes.root}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <Select
                id={id}
                value={value}
                classes={{
                    root: classes.input,
                }}
                onChange={(event) => handleChange(event.target.value as DateOptions)}
                label={label}
            >
                {Object.keys(DATES).map((key, idx: number) => (
                    <MenuItem key={idx} value={key}>
                        {/* @ts-expect-error */}
                        {t(`dateFilter.${key}`)}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default DateFilter;
