import { Grid, Link, makeStyles, Theme } from "@material-ui/core";

import bgImage from "assets/images/Pand-1440.jpg";
import veluImage from "assets/images/velu.svg";
import solidAirImage from "assets/images/solid_air.svg";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        overflow: "hidden",
        justifyContent: "flex-end",
        width: "100%",
        height: "100vh",
    },
    imageWrap: {
        flex: "1 1 70%",
        overflow: "hidden",
        zIndex: 9,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    },
    loginRoot: {
        flex: "0 1 700px",
        position: "relative",
        alignSelf: "flex-start",
        zIndex: 10,
        marginTop: "5%",
        transform: "translateX(10vw)",
        width: 500,
    },
    companyLogoVelu: {
        width: 140,
        marginTop: theme.spacing(4),
    },
    companyLogoSolid: {
        width: 90,
        marginTop: theme.spacing(4),
    },
}));

const LayoutWrap: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.loginRoot}>
                <Grid item xs={12}>
                    {children}
                </Grid>
                <Grid item xs={3}>
                    <Link href="https://www.solid-air.nl" target="_blank" rel="noreferrer">
                        <img src={solidAirImage} alt="Solid Air" className={classes.companyLogoSolid} />
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link href="https://www.velu.nl" target="_blank" rel="noreferrer">
                        <img src={veluImage} alt="Velu" className={classes.companyLogoVelu} />
                    </Link>
                </Grid>
            </Grid>
            <div className={classes.imageWrap}></div>
        </div>
    );
};

export default LayoutWrap;
