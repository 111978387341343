import { Container, Typography, makeStyles, Theme, Grid, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import image from "assets/images/marieke-cornelius.png";
import { RenderTitle } from "shared";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(6, 0),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    contactBlock: {
        backgroundColor: theme.palette.common.white,
        borderRadius: `0 0 0 10px`,
        marginTop: theme.spacing(4),
    },
    image: {
        overflow: "hidden",
        height: 210,
        maxWidth: 175,
        width: 175,
        padding: 0,
        borderRadius: `0 0 0 10px`,
        display: "flex",
        alignItems: "flex-end",
        "& img": {
            width: "100%",
        },
    },
    info: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        flex: 1,
        padding: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        color: theme.palette.common.white,
    },
    icon: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        width: 40,
        height: 40,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: 6,
        marginRight: theme.spacing(2),
    },
    iconBlack: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        width: 40,
        height: 40,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: 6,
        marginRight: theme.spacing(2),
    },
    row: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    innerBlock: {
        marginTop: 30,
        padding: 30,
    },
    linkWhite: {
        color: theme.palette.common.white,
        fontSize: 14,
        letterSpacing: 0.5,
    },
    linkBlack: {
        color: theme.palette.common.black,
        fontSize: 14,
        letterSpacing: 0.5,
    },
    company: {
        flex: 1,
        marginTop: 30,
        display: "flex",
        flexDirection: "row",
    },
    companyAddress: {
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    companyInfo: {
        backgroundColor: theme.palette.common.white,
        paddingLeft: 35,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
}));

const Contact: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    type CompanyType = "solid" | "velu";
    const Companies: CompanyType[] = ["solid", "velu"];

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <RenderTitle element="h1" text={t("contact.title")} />
                <Typography variant="body1">{t("contact.introText")}</Typography>
                <Typography variant="subtitle1" color="primary">
                    {t("contact.person.title")}
                </Typography>
                <Grid container className={classes.contactBlock}>
                    <Grid item className={classes.image}>
                        <img src={image} alt="" />
                    </Grid>
                    <Grid item className={classes.info}>
                        <Typography variant="h3" color="inherit">
                            {t("contact.person.name")}
                        </Typography>
                        <Typography variant="body2" gutterBottom={true}>
                            {t("contact.person.jobTitle")}
                        </Typography>
                        <Typography className={classes.row} variant="body2">
                            <PhoneIcon className={classes.icon} fontSize="small" />
                            {t("contact.person.phone")}
                        </Typography>
                        <Typography className={classes.row} variant="body2">
                            <EmailIcon className={classes.icon} fontSize="small" />
                            <Link href={`mailto:${t("contact.person.email")}`} className={classes.linkWhite}>
                                {t("contact.person.email")}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>

                {/* Render al companies */}
                {Companies.map((company: CompanyType, idx: number) => (
                    <div className={classes.contactBlock} key={idx}>
                        <div className={classes.innerBlock}>
                            <Typography variant="h3">{t(`contact.${company}.title`)}</Typography>
                            <Typography> {t(`contact.${company}.intro`)} </Typography>
                            <Grid container className={classes.company}>
                                <Grid item className={classes.companyAddress}>
                                    <Typography variant="h4" color="inherit" gutterBottom={true}>
                                        {t(`contact.${company}.addressTitle`)}
                                    </Typography>
                                    <Typography variant="caption">{t(`contact.${company}.addressLine1`)}</Typography>
                                    <Typography variant="caption"> {t(`contact.${company}.addressLine2`)} </Typography>
                                    <Typography variant="caption">{t(`contact.${company}.addressLine3`)}</Typography>
                                </Grid>
                                <Grid item className={classes.companyInfo}>
                                    <Typography className={classes.row} variant="body2">
                                        <PhoneIcon className={classes.iconBlack} fontSize="small" />
                                        {t(`contact.${company}.phoneNumber`)}
                                    </Typography>
                                    <Typography className={classes.row} variant="body2">
                                        <EmailIcon className={classes.iconBlack} fontSize="small" />
                                        <Link
                                            href={`mailto:${t(`contact.${company}.email`)}`}
                                            className={classes.linkBlack}
                                            color="inherit"
                                        >
                                            {t(`contact.${company}.email`)}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default Contact;
