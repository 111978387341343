import { Typography, Button, makeStyles, Theme } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { selectCount } from "store/slices/OrderSlice";

type StyledProps = {
    offset: number;
};

const useStyles = makeStyles<Theme, StyledProps>((theme: Theme) => ({
    root: (props) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
        padding: theme.spacing(4),
        position: "sticky",
        top: props.offset + "px",
    }),
    text: {
        marginBottom: theme.spacing(2),
    },
    button: {
        fontWeight: 700,
    },
}));

type Props = {
    offsetTop?: number;
};

const ArticleCounter: React.FC<Props> = ({ offsetTop }) => {
    const { t } = useTranslation();
    const classes = useStyles({ offset: offsetTop || 0 });
    const counter = useAppSelector((state) => selectCount(state.order));

    return (
        <div className={classes.root}>
            <Typography className={classes.text} variant="subtitle1">
                {t("returns.title")}
            </Typography>
            <Typography className={classes.text} variant="subtitle1">
                {counter} {counter === 1 ? t("returns.article") : t("returns.articles")}
            </Typography>
            <Button
                className={classes.button}
                component={Link}
                disabled={counter <= 0}
                variant="contained"
                color="primary"
                to="/orders/edit-return"
                endIcon={<ArrowRightIcon fontSize="large" />}
            >
                {t("returns.next")}
            </Button>
        </div>
    );
};

export default ArticleCounter;
