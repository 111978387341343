import { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertDate } from "helpers";
import { useAppSelector, useOrder } from "hooks";
import { RETURN_REASON } from "const";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Box, Button, Typography, Grid, Divider } from "@material-ui/core";

import { CustomNumberInput, ReturnReasonSelector, RenderTitle, PopUp, CustomFileUpload } from "shared";

type Props = {
    open: boolean;
    onClose: () => void;
    orderContentId: string;
    orderLineContentId: string;
};

const ReturnOrderDialog: React.FC<Props> = ({ onClose, open, orderContentId, orderLineContentId }) => {
    const { t } = useTranslation();

    const { changeValueOnOrderLine } = useOrder();
    const [returnError, setReturnError] = useState(false);
    const [returnSelectError, setReturnSelectError] = useState(false);

    const storeReturnOrders = useAppSelector((state) => state.order.returnOrders);
    const order = storeReturnOrders.find((order) => order.contentItemId === orderContentId);
    const orderLine = order?.lines.find((line) => line.contentItemId === orderLineContentId);

    const handleStateChange = (
        key: string,
        value: string | number | FileList,
        orderId: string,
        orderLineId: string
    ) => {
        changeValueOnOrderLine(key, value, orderId, orderLineId);
    };

    if (!order || !orderLine) {
        onClose();
        return null;
    }

    const handleClose = () => {
        setReturnError(false);
        setReturnSelectError(false);
        if (orderLine?.returnCount <= 0) {
            setReturnError(true);
        }
        if (orderLine?.reason === RETURN_REASON.EMPTY) {
            setReturnSelectError(true);
        }
        if (orderLine?.returnCount <= 0 || orderLine?.reason === RETURN_REASON.EMPTY) return;

        onClose();
    };

    return (
        <PopUp
            open={open}
            onClose={onClose}
            content={() => (
                <>
                    <Box pl={4} pr={4} pt={4} pb={2}>
                        <RenderTitle element="h3" text={t("returnDialog.title")} />
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">{order?.orderId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2">{convertDate(order?.shippedOn)}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2">{order?.projectName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Typography variant="subtitle2">{order?.customerId}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle2"></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2">{order?.customerReference}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    {orderLine && (
                        <Box pl={4} pr={4} pt={2} pb={2}>
                            <RenderTitle element="h2" text={t("returnDialog.article")} line={true} />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={2}>
                                    <Typography variant="subtitle1">{orderLine?.articleNumber}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="subtitle2">{orderLine?.allowedReturnQuantity}x</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{orderLine?.productDescription}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Divider />
                    <Box p={4}>
                        <Grid container direction="row" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <CustomNumberInput
                                    value={orderLine?.returnCount || 0}
                                    maxValue={orderLine?.allowedReturnQuantity || 0}
                                    onChange={(value) =>
                                        handleStateChange(
                                            "returnCount",
                                            value,
                                            order?.contentItemId,
                                            orderLine?.contentItemId
                                        )
                                    }
                                    label={t("amountLabel")}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ReturnReasonSelector
                                    id="reason"
                                    value={orderLine?.reason || RETURN_REASON.EMPTY}
                                    onChange={(value) =>
                                        handleStateChange(
                                            "reason",
                                            value,
                                            order?.contentItemId,
                                            orderLine?.contentItemId
                                        )
                                    }
                                    label={t("returnReasonLabel")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <CustomFileUpload
                                    label={t("documents")}
                                    value={orderLine.tempFiles}
                                    onChange={(files) =>
                                        changeValueOnOrderLine(
                                            "tempFiles",
                                            files,
                                            order?.contentItemId,
                                            orderLine?.contentItemId
                                        )
                                    }
                                    id="documents"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pl={4}>
                        <Grid container direction="row" spacing={0} justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={3}>
                                {returnError && (
                                    <Typography variant="body2" color="error">
                                        {t("returnDialog.returnCountError")}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                {returnSelectError && (
                                    <Typography variant="body2" color="error">
                                        {t("returnDialog.chooseReasonError")}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            buttons={() => (
                <Button
                    onClick={handleClose}
                    variant="contained"
                    autoFocus
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="large" />}
                >
                    {t("returnDialog.save")}
                </Button>
            )}
        />
    );
};

export default ReturnOrderDialog;
