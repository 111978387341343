import { gql } from "@apollo/client";

export const GET_ALL_ORDERS = gql`
    query GetAllOrders($term: String!, $skip: Int, $take: Int) {
        orders(term: $term, skip: $skip, take: $take) {
            totalCount
            skip
            take
            items {
                city
                contentItemId
                customerId
                orderId
                postalCode
                projectName
                purchaseNumber
                shippedOn
                street
                customerReference
                lines {
                    allowedReturnQuantity
                    articleNumber
                    contentItemId
                    isReturnable
                    lineNumber
                    orderId
                    productDescription
                    productName
                    quantity
                    shippedOn
                }
            }
        }
    }
`;

export const GET_RETURN_ORDERS = gql`
    query ReturnOrders($term: String, $skip: Int, $take: Int, $fromDate: DateTime, $status: ReturnOrderStatus) {
        returnOrders(term: $term, status: $status, fromDate: $fromDate, skip: $skip, take: $take) {
            totalCount
            take
            skip
            items {
                city
                contactPerson
                contentItemId
                createdOn
                houseNumber
                orderId
                phoneNumber
                pickupDate
                finalPickupDate
                postalCode
                returnMethod
                returnOrderId
                status
                statusReason
                street
                confirmationDocument
                creator
                lines {
                    articleNumber
                    contentItemId
                    productName
                    reason
                    quantity
                    returnOrderId
                    status
                    statusReason
                    media
                }
            }
        }
    }
`;

export const GET_ALL_FAQS = gql`
    query Faqs {
        faqs {
            skip
            take
            totalCount
            items {
                title
                questions {
                    question
                    answer
                }
            }
        }
    }
`;

export const GET_CURRENT_USER = gql`
    query CurrentUser {
        currentUser {
            firstName
            lastName
            middleName
            phoneNumber
            status
            user {
                email
            }
            customer {
                area
                customerId
                name
                postalAddress {
                    city
                    postalCode
                    street
                }
                invoiceAddress {
                    city
                    postalCode
                    street
                }
            }
        }
    }
`;

export const GET_CUSTOMERS = gql`
    query customers($term: String!) {
        customers(term: $term) {
            items {
                contentItemId
                customerId
                name
            }
        }
    }
`;

export const GET_CONTACTS = gql`
    query contacts($customerId: String!, $take: Int) {
        contacts(customerId: $customerId, take: $take) {
            take
            items {
                firstName
                user {
                    email
                    id
                }
                lastName
                status
            }
        }
    }
`;
