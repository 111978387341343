import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER_ROLES } from "const";
import { User } from "types";

// Define a type for the slice state
export type AuthState = {
    isAuthenticated: boolean;
    user: User;
};

// Define the initial state using that type
const initialState: AuthState = {
    isAuthenticated: false,
    user: { name: "", firstName: "", lastName: "", email: "", role: USER_ROLES.Nijburg_Customer, permissions: [] },
};

export const AuthSlice = createSlice({
    name: "auth",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        login: (state) => {
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = { name: "", firstName: "", lastName: "", email: "", role: USER_ROLES.Nijburg_Customer, permissions: [] };
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = {
                name: action.payload.name,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                role: action.payload.role,
                permissions: action.payload.permissions,
                allowedCustomers: action.payload.allowedCustomers,
                impersonating: action.payload.impersonating,
                impersonator: action.payload.impersonator,
            };
        },
    },
});

export const { login, logout, setUser } = AuthSlice.actions;

export default AuthSlice.reducer;
