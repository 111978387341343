import { useTranslation } from "react-i18next";

import { InputLabel, makeStyles, Theme, FormControl, Button } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { formatFilelistToString } from "helpers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(2),

        "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
        },
    },
    label: {
        position: "relative",
        transform: "none",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: theme.palette.text.primary,
        marginBottom: 10,

        "& + .MuiInput-formControl": {
            marginTop: theme.spacing(2),
        },
    },

    button: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #CED5D8",
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        padding: "6px 15px",
    },
}));

type Props = {
    onChange: (files: FileList) => void;
    value: FileList | undefined;
    id: string;
    label: string;
};

const CustomFileUpload: React.FC<Props> = ({ label, value, id, onChange }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            onChange(files);
        }
    };

    return (
        <FormControl className={classes.root}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <label htmlFor={id}>
                <input
                    id={id}
                    name={id}
                    style={{ display: "none" }}
                    type="file"
                    multiple={true}
                    onChange={handleFileChange}
                />
                <Button className={classes.button} variant="outlined" component="span">
                    {value && <div>{formatFilelistToString(value)}</div>}
                    {!value && <span>{t("chooseFiles")}</span>}
                    <ControlPointIcon fontSize="small" />
                </Button>
            </label>
        </FormControl>
    );
};

export default CustomFileUpload;
