import { createTheme } from "@material-ui/core";

// define theme variables
const borderRadius: number = 5;
const padding: number = 5;
const darkBlue: string = "#003087";
const fontSize14: string = "0.875rem";
const textSecondary = "#575656";

// A custom theme for this app
const theme = createTheme({
    mixins: {
        toolbar: {
            height: 80,
        },
    },
    palette: {
        primary: {
            main: darkBlue,
        },
        secondary: {
            main: "#E6EAEB",
        },
        background: {
            default: "#E6EAEB",
            paper: "#fff",
        },
        text: {
            primary: "#000",
            secondary: textSecondary,
        },
    },
    typography: {
        fontFamily: "TheSans C4s",
        htmlFontSize: 16,
        fontSize: 16,
        h1: {
            color: darkBlue,
            fontSize: "1.5rem",
            fontWeight: "bold",
            letterSpacing: "1.2px",
            lineHeight: "1.25rem",
            textTransform: "uppercase",
        },
        h2: {
            color: darkBlue,
            fontSize: "1.125rem",
            fontWeight: "bold",
            letterSpacing: "1.2px",
            lineHeight: "1.25rem",
            textTransform: "uppercase",
        },
        h3: {
            color: textSecondary,
            fontSize: "1.125rem",
            fontWeight: "bold",
            letterSpacing: "1.2px",
            lineHeight: "1.25rem",
            textTransform: "none",
        },
        h4: {
            color: textSecondary,
            fontSize: "0.875rem",
            fontWeight: "bold",
            letterSpacing: "1.2px",
            lineHeight: "1.25rem",
            textTransform: "none",
        },
        body1: {
            fontSize: fontSize14,
            letterSpacing: "0.5px",
        },
        body2: {
            fontSize: "0.78125rem",
            letterSpacing: "0.5px",
        },
        subtitle1: {
            fontSize: fontSize14,
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: "1.225rem",
        },
        subtitle2: {
            fontSize: fontSize14,
            fontWeight: 300,
            letterSpacing: "0.5px",
            lineHeight: "1.225rem",
        },
    },
    shape: {
        borderRadius: borderRadius,
    },
    spacing: padding,
    breakpoints: {
        values: {
            xs: 420,
            sm: 670,
            md: 770,
            lg: 970,
            xl: 1920,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                whiteSpace: "pre-line",
            },
            h1: {
                marginBottom: padding * 2,
            },
            h2: {
                marginBottom: padding * 2,
            },
            h3: {
                marginBottom: padding * 2,
            },
        },
        MuiAppBar: {
            root: {
                background: "linear-gradient(90deg, #FFFFFF 0%, #EEEEEE 100%)",
                borderRadius: `0 ${borderRadius * 2}px 0 ${borderRadius * 2}px`,
                boxShadow: "none",
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "#fff",
                borderRadius: borderRadius,
                "&::before": {
                    //display: "none",
                },
                "&::after": {
                    //display: "none",
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: `0 ${borderRadius}px 0 ${borderRadius}px`,
                fontSize: fontSize14,
                padding: `${padding * 2}px ${padding * 4}px`,
            },
            label: {
                textTransform: "none",
            },
        },
        MuiTextField: {
            root: {
                marginBottom: padding * 4,
            },
        },
        MuiCheckbox: {
            root: {
                margin: 0,
                padding: 0,
            },
        },
        MuiGrid: {
            item: {
                display: "flex",
                alignItems: "center",
            },
        },
        MuiFormControl: {
            root: {
                width: "100%",
            },
        },
        MuiInputBase: {
            root: {
                "&::before": {
                    display: "none",
                },
                "&::after": {
                    display: "none",
                },
            },
            input: {
                background: "#fff",
                borderRadius: borderRadius,
            },
        },
        MuiFormLabel: {
            root: {
                fontWeight: 600,
                color: "#000",
            },
        },
        MuiFormControlLabel: {
            label: {
                fontWeight: 600,
                color: "#000",
            },
        },
        MuiAccordion: {
            root: {
                margin: "16px 0",
                borderRadius: "0 10px 0 10px",
                "&.Mui-expanded": {
                    borderRadius: "0 10px 0 0px",
                },
                "&::before": {
                    background: "none",
                },
            },
            rounded: {
                borderRadius: "0 10px 0 10px",
                "&::last-child": {
                    borderRadius: "0 10px 0 10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                },
            },
        },
    },
});

export default theme;
