import clsx from "clsx";
import { InputLabel, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ChangeEvent, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    wrap: {
        display: "flex",
    },
    label: {
        marginBottom: theme.spacing(2),
    },
    button: {
        border: "1px solid #ced5d8",
        flex: "0 0 33%",
        padding: "10px 0",
        height: 38,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        backgroundColor: "rgba(255,255,255,0.54)",
        "&:first-child": {
            borderRadius: "0 0 0 10px",
        },
        "&:last-child": {
            borderRadius: "0 10px 0 0",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            "& svg": {
                color: theme.palette.common.white,
            },
        },
    },
    number: {
        border: "1px solid #ced5d8",
        flex: "0 0 33%",
        width: 33,

        height: 38,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,
    },
    error: {
        border: "1px solid red",
    },
}));

type Props = {
    value: number;
    onChange: (value: number) => void;
    maxValue: number;
    label: string;
    error?: boolean;
};

const CustomNumberInput: React.FC<Props> = ({ value, onChange, label, maxValue, error = false }) => {
    const classes = useStyles();

    const [internalValue, setInternalValue] = useState<number>(value);

    const min = () => {
        let newVal = internalValue - 1;
        if (newVal < 0) return;
        setInternalValue(newVal);
    };

    const plus = () => {
        let newVal = internalValue + 1;
        if (newVal > maxValue) {
            setInternalValue(maxValue);
            return;
        }
        setInternalValue(newVal);
    };

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = Number(e.target.value);

        if (Number.isNaN(newVal)) {
            setInternalValue(0);
            return;
        }

        if (newVal > maxValue) {
            setInternalValue(maxValue);
            return;
        }
        setInternalValue(newVal);
    };

    useEffect(() => {
        let value = internalValue;

        onChange(value);
    }, [internalValue, onChange]);

    return (
        <div className={classes.root}>
            {label && <InputLabel className={classes.label}>{label}</InputLabel>}
            <div className={classes.wrap}>
                <div className={classes.button} onClick={min}>
                    <RemoveIcon color="inherit" fontSize="small" />
                </div>
                <input
                    type="text"
                    value={internalValue ? internalValue : `${internalValue}x`}
                    className={clsx(classes.number, { [classes.error]: error })}
                    onChange={handleValueChange}
                />
                <div className={classes.button} onClick={plus}>
                    <AddIcon color="inherit" fontSize="small" />
                </div>
            </div>
        </div>
    );
};

export default CustomNumberInput;
