import { useState } from "react";
import SnackBarContext from "./SnackBarContext";

type BarType = "error" | "warning" | "info" | "success";
const duration = 6000;

export type SnackBarApi = {
    open: boolean;
    duration: number;
    message: string;
    type: BarType;
    snackBarApi: {
        showSnackBar: (message: string, type: BarType) => void;
        closeSnackBar: () => void;
    };
};

const SnackBarProvider: React.FC = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState<BarType>("success");

    const showSnackBar = (message: string, type: BarType) => {
        if (message) {
            setMessage(message);
        }
        if (type) {
            setType(type);
        }
        setOpen(true);
    };

    const closeSnackBar = () => {
        setOpen(false);
    };

    const value: SnackBarApi = {
        open,
        duration,
        message,
        type,
        snackBarApi: {
            showSnackBar: showSnackBar,
            closeSnackBar: closeSnackBar,
        },
    };

    return <SnackBarContext.Provider value={value}>{children}</SnackBarContext.Provider>;
};

export default SnackBarProvider;
