import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
    Container,
    Typography,
    makeStyles,
    Theme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { GET_ALL_FAQS } from "api/Queries";
import { Loader } from "shared";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        justifyContent: "flex-start",
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: "0 10px 0 10px",
        "&.Mui-expanded": {
            minHeight: "auto",
        },
    },
    accordionRoot: {
        padding: theme.spacing(4),
    },
    content: {
        "&.Mui-expanded": {
            margin: "12px 0",
        },
    },
    button: {
        color: theme.palette.common.white,
    },
    questionBlock: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0 5px 0 5px",
        padding: theme.spacing(2, 4),
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
    },
    link: {
        color: theme.palette.text.secondary,
        fontStyle: "italic",
        marginLeft: theme.spacing(1),
    },
    returnLink: {
        color: theme.palette.text.secondary,
    },
}));

type Faq = {
    answer: string;
    question: string;
};

const ReturnPolicy = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data, loading, error } = useQuery(GET_ALL_FAQS);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <Typography variant="h1">{t("returnPolicy.title")}</Typography>
                <Typography variant="body1">{t("returnPolicy.introText")}</Typography>
                {loading && <Loader />}
                {!loading &&
                    !error &&
                    data &&
                    data.faqs.items[0] &&
                    data.faqs.items[0].questions.map((item: Faq, idx: number) => (
                        <Accordion key={idx}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon color="inherit" />}
                                id={`question-${idx}`}
                                className={classes.heading}
                                classes={{
                                    root: classes.heading,
                                    content: classes.content,
                                    expandIcon: classes.button,
                                }}
                            >
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                classes={{
                                    root: classes.accordionRoot,
                                }}
                            >
                                <Typography variant="body1">{item.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                <div className={classes.questionBlock}>
                    <Typography variant="body1">{t("contact.otherQuestions")}</Typography>
                    <Link to="/contact" className={classes.link}>
                        {t("contact.otherQuestionsLink")}
                    </Link>
                </div>

                <div className={classes.questionBlock}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t("veluTitle")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t("solidTitle")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => event.stopPropagation()}
                                href="assets/pdf/Retourvoorwaarden_Velu_Klimaattechnische_Groothandel.pdf"
                                className={classes.returnLink}
                                color="inherit"
                            >
                                {t("downloadReturnTerms")}
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => event.stopPropagation()}
                                className={classes.returnLink}
                                color="inherit"
                                href="assets/pdf/Retourvoorwaarden_Solid_Air_Climate_Solutions.pdf"
                            >
                                {t("downloadReturnTerms")}
                            </a>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

export default ReturnPolicy;
