import { useState } from "react";
import { useTranslation } from "react-i18next";

import { EditOutlined } from "@material-ui/icons";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Divider, Grid, Theme, Typography, makeStyles, Box, Button } from "@material-ui/core";

import { useOrder } from "hooks";
import { PopUp } from "shared";
import { ReturnOrderLineType } from "types";
import { formatFilelistToString, renderMaxTextLength } from "helpers";

import ReturnOrderDialog from "./ReturnOrderDialog";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        justifyContent: "flex-start",
    },
    orderRoot: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    heading: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4),
        borderRadius: "0 5px 0 5px",
        boxShadow: "0 5px 20px 0 rgba(87,86,86,0.15)",
        marginBottom: theme.spacing(8),
    },
    button: {
        marginTop: theme.spacing(4),
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    noLine: {
        "&::after": {
            display: "none",
        },
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        height: 30,
        marginBottom: 0,
        width: 70,
        "& .MuiInputBase-root": {
            height: 30,
            padding: 0,
        },
        "& .MuiInputBase-input": {
            padding: theme.spacing(1, 2),
        },
    },
    lineButton: {
        width: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

type Props = {
    orderLine: ReturnOrderLineType;
    orderId: string;
};

const ReturnOrderLine: React.FC<Props> = ({ orderLine, orderId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { removeOrderLineFromState } = useOrder();

    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const cancelReturn = () => {
        setOpenDialog(true);
    };

    const removeReturnOrderLineFromState = () => {
        setOpenDialog(false);
        removeOrderLineFromState(orderId, orderLine.contentItemId);
    };

    return (
        <>
            <div key={orderLine.contentItemId} className={classes.orderRoot}>
                <Box pl={3} pr={3} pt={2} pb={2}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">{orderLine.articleNumber}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>{orderLine.returnCount}x</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">
                                {renderMaxTextLength(orderLine.productDescription, 50, true)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box pl={3} pr={3} pt={2} pb={2}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Box className={classes.flexBox}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t("orderLine.orderNumber")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {orderLine.orderId}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box className={classes.flexBox}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t("orderLine.returnReason")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {t(`returnReason.${orderLine.reason}`)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box className={classes.flexBox}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {t("orderLine.documents")}
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {orderLine.tempFiles && formatFilelistToString(orderLine.tempFiles)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <div>
                                <Button
                                    className={classes.lineButton}
                                    onClick={() => setOpenEdit(true)}
                                    color="primary"
                                    startIcon={<EditOutlined />}
                                />
                                <Button
                                    className={classes.lineButton}
                                    onClick={cancelReturn}
                                    color="primary"
                                    startIcon={<CancelOutlinedIcon />}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <PopUp
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                content={() => (
                    <>
                        <Box p={4}>
                            <Typography variant="h2">{t("orderLine.cancel")}</Typography>
                        </Box>
                        <Divider />
                        <Box p={4}>
                            <Typography variant="body1">{t("orderLine.dialogText")}</Typography>
                        </Box>
                    </>
                )}
                buttons={() => (
                    <>
                        <Button onClick={() => setOpenDialog(false)} color="primary">
                            {t("orderLine.cancel")}
                        </Button>
                        <Button onClick={removeReturnOrderLineFromState} variant="contained" color="primary" autoFocus>
                            {t("orderLine.delete")}
                        </Button>
                    </>
                )}
            />

            <ReturnOrderDialog
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                orderContentId={orderId}
                orderLineContentId={orderLine.contentItemId}
            />
        </>
    );
};

export default ReturnOrderLine;
