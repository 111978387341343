import { Route } from "react-router-dom";

type Props = {
    path: string;
    component: React.ComponentType<any>;
    exact?: boolean;
};

const PublicRoute: React.FC<Props> = ({ component: Component, exact, path, ...rest }) => (
    <Route path={path} exact={exact} {...rest} render={() => <Component />} />
);

export default PublicRoute;
