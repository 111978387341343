import { Link } from "react-router-dom";

import { Container, Typography, makeStyles, Theme, Grid, Button } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        padding: theme.spacing(6, 0),
        justifyContent: "flex-start",
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    paper: {
        padding: theme.spacing(4),
        borderRadius: "0 5px 0 5px",
        boxShadow: "0 5px 20px 0 rgba(87,86,86,0.15)",
    },
    datePicker: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ced5d8",
        height: 40,
        padding: theme.spacing(1, 0, 1, 2),
        marginBottom: 0,
        marginTop: theme.spacing(2),
    },
    addressWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(4),
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
}));

const ReturnSuccess = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <Grid container direction="row">
                    <Grid item xs={8} className={classes.header}>
                        <Typography variant="h1">{t("returnSend.title")}</Typography>
                        <Typography variant="body1">{t("returnSend.introText")}</Typography>
                        <Button
                            component={Link}
                            to="/return-orders"
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            endIcon={<ArrowRightIcon />}
                        >
                            {t("returnSend.buttonText")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default ReturnSuccess;
