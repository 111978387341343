import { useAppDispatch, useAppSelector } from "./useStore";
import { OrderLineType, OrderType, ReturnOrderLineType, ReturnOrderType } from "types";
import { setCurrentOrderLine, setCurrentOrder, addReturnOrders, clearState } from "store/slices/OrderSlice";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useSnackBar } from "hooks";

const UseOrder = () => {
    const dispatch = useAppDispatch();
    const stateOrders = useAppSelector((state) => state.order.returnOrders);
    const { snackBarApi } = useSnackBar();

    const changeCurrentOrderLine = (article: OrderLineType) => {
        dispatch(setCurrentOrderLine(article));
    };

    const changeCurrentOrder = (order: OrderType) => {
        dispatch(setCurrentOrder(order));
    };

    const clearOrderState = () => {
        dispatch(clearState());
    };

    const changeValueOnOrderLine = (
        key: string,
        value: string | number | FileList,
        orderId: string,
        orderLineId: string
    ) => {
        if (!orderLineId || !orderId || !key) return;

        // find the current Order
        const currentOrderIndex = stateOrders.findIndex((order: ReturnOrderType) => order.contentItemId === orderId);

        if (currentOrderIndex < 0) return;
        // if order exist make a copy of the orderDate
        let tempOrder = { ...stateOrders[currentOrderIndex] };

        // find the current orderLine and make a copy
        let currentArticleIndex = tempOrder?.lines.findIndex(
            (item: ReturnOrderLineType) => item.contentItemId === orderLineId
        );
        if (currentArticleIndex < 0) return;

        // change the current orderline an update the order with new orderline
        const lines = [...tempOrder.lines];
        lines[currentArticleIndex] = {
            ...lines[currentArticleIndex],
            [key]: value,
        };

        tempOrder.lines = lines;

        // change the order from the state and add a new order
        const orders = [...stateOrders];
        orders[currentOrderIndex] = {
            ...orders[currentOrderIndex],
            ...tempOrder,
        };

        // update the order state with the new orders array
        dispatch(addReturnOrders(orders));
    };

    const changeValueOnOrder = (key: string, value: string | number | MaterialUiPickersDate, contentItemId: string) => {
        if (!contentItemId || !key) return;
        const orders = [...stateOrders];
        // find the current Order
        const currentOrderIndex = orders.findIndex((order: ReturnOrderType) => order.contentItemId === contentItemId);

        if (currentOrderIndex < 0) return;

        // if order exist make a copy of the orderDate
        orders[currentOrderIndex] = {
            ...orders[currentOrderIndex],
            [key]: value,
        };

        // update the order state with the new orders array
        dispatch(addReturnOrders(orders));
    };

    const changeAddressOnOrder = (
        address: { street: string; city: string; postalCode: string },
        contentItemId: string
    ) => {
        if (!contentItemId || !address) return;

        const orders = [...stateOrders];
        // find the current Order
        const currentOrderIndex = orders.findIndex((order: ReturnOrderType) => order.contentItemId === contentItemId);

        if (currentOrderIndex < 0) return;

        // if order exist make a copy of the orderDate
        orders[currentOrderIndex] = {
            ...orders[currentOrderIndex],
            street: address.street,
            postalCode: address.postalCode,
            city: address.city,
        };

        // update the order state with the new orders array
        dispatch(addReturnOrders(orders));
    };

    const removeOrderLineFromState = (contentItemId: string, orderLineContextId: string) => {
        if (!contentItemId && !orderLineContextId) return;
        // find the current Order
        const currentOrderIndex = stateOrders.findIndex(
            (order: ReturnOrderType) => order.contentItemId === contentItemId
        );
        let tempOrder = { ...stateOrders[currentOrderIndex] };

        if (tempOrder && Object.keys(tempOrder).length === 0 && Object.getPrototypeOf(tempOrder) === Object.prototype)
            return;

        // copy the lines to new array en filter out the orderline ID
        const lines = [...tempOrder.lines];
        const filterLines = lines.filter((line) => line.contentItemId !== orderLineContextId);
        tempOrder.lines = filterLines;

        // copy orders to new array en overwrite the current order
        let orders = [...stateOrders];

        // if no return lines remove order from state
        if (tempOrder.lines.length <= 0) {
            orders = orders.filter((order) => order.contentItemId !== contentItemId);
        } else {
            orders[currentOrderIndex] = {
                ...orders[currentOrderIndex],
                ...tempOrder,
            };
        }
        snackBarApi.showSnackBar("Order regel succesvol verwijderd", "success");
        dispatch(addReturnOrders(orders));
    };

    return {
        changeValueOnOrderLine,
        changeValueOnOrder,
        removeOrderLineFromState,
        changeCurrentOrder,
        clearOrderState,
        changeCurrentOrderLine,
        changeAddressOnOrder,
    };
};

export default UseOrder;
