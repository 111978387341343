import { FormControl, Input, InputLabel, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    label: {
        position: "relative",
        transform: "none",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: "0.5px",
        color: theme.palette.text.primary,
        "& + .MuiInput-formControl": {
            marginTop: theme.spacing(2),
        },
    },
    input: {
        borderRadius: theme.shape.borderRadius,
        background: "#fff",
        border: "1px solid #CED5D8",
        padding: "5px 15px 5px 15px",
        fontSize: 14,
        "&::before": {
            display: "none",
        },
        "&::after": {
            display: "none",
        },
        "&.Mui-error": {
            border: "1px solid red",
        },
    },
}));

type Props = {
    id: string;
    label?: string;
    type: string;
    value?: string;
    placeholder?: string;
    error?: boolean;
    onChange: (value: string, id: string) => void;
    disabled?: boolean;
};

const CustomInput: React.FC<Props> = ({
    id,
    label,
    type,
    onChange,
    value,
    error,
    disabled = false,
    placeholder = "",
}) => {
    const classes = useStyles();
    const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(value, id);
    };

    return (
        <FormControl className={classes.root}>
            {label && (
                <InputLabel
                    shrink={false}
                    htmlFor={id}
                    classes={{
                        formControl: classes.label,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Input
                error={error}
                className={classes.input}
                placeholder={placeholder}
                id={id}
                value={value}
                type={type}
                onChange={changeInput}
                disabled={disabled}
            />
            {/* <TextField id="email" label="e-mailadres" type="email" variant="outlined" /> */}
        </FormControl>
    );
};

export default CustomInput;
