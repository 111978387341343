import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select, makeStyles, Theme } from "@material-ui/core";
import { DECLINE_RETURN_REASON, RETURN_REASON } from "const";

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        position: "relative",
        transform: "none",
        marginBottom: 10,
    },
    input: {
        height: "auto",
        padding: 10,
        border: "1px solid #CED5D8",
        "& .MuiInput-input": {
            padding: "0 8px",
            marginTop: 10,
        },
        "& .MuiSelect-root": {
            border: "1px solid black",
        },
    },
    formRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
        },
    },
}));

type Props = {
    onChange: (value: typeof DECLINE_RETURN_REASON[keyof typeof DECLINE_RETURN_REASON]) => void;
    value:
        | typeof DECLINE_RETURN_REASON[keyof typeof DECLINE_RETURN_REASON]
        | typeof RETURN_REASON[keyof typeof RETURN_REASON];
    id: string;
    label: string;
};

const DeclineReasonSelector: React.FC<Props> = ({ onChange, value, id, label }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClick = (value: typeof DECLINE_RETURN_REASON[keyof typeof DECLINE_RETURN_REASON]) => {
        onChange(value);
    };

    return (
        <FormControl className={classes.formRoot} variant="outlined">
            <InputLabel className={classes.label} shrink={false}>
                {label}
            </InputLabel>
            <Select
                labelId={id}
                id={id}
                value={value}
                onChange={(event) =>
                    handleClick(event.target.value as typeof DECLINE_RETURN_REASON[keyof typeof DECLINE_RETURN_REASON])
                }
                label={t("returnReasonLabel")}
                classes={{
                    root: classes.input,
                }}
            >
                {Object.keys(DECLINE_RETURN_REASON).map((key, idx) => {
                    let currentKey = (DECLINE_RETURN_REASON as any)[key];
                    return (
                        <MenuItem key={idx} value={currentKey}>
                            {/* @ts-expect-error */}
                            {t(`declineReturnReason.${currentKey}`)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default DeclineReasonSelector;
