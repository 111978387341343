import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, RenderTitle } from "shared";

import {
    Container,
    Typography,
    makeStyles,
    Theme,
    FormControl,
    InputLabel,
    FilledInput,
    InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useLazyQuery } from "@apollo/client";
import { GET_CUSTOMERS } from "../../api/Queries";
import { Customer } from "../../types";
import ContactsDialog from "./parts/ContactsDialog";
import CustomerRow from "./parts/CustomerRow";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: theme.spacing(6, 0),
    },
    wrap: {
        maxWidth: theme.breakpoints.values.md,
    },
    formRoot: {
        width: 680,
        margin: theme.spacing(4, 0),
    },
    searchInput: {
        width: "auto",
    },
}));

const Impersonate = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [term, setTerm] = useState('');
    const customersWrap = useRef<HTMLDivElement>(null);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [customerId, setCustomerId] = useState('');

    const [getCustomers, { loading, error, data }] = useLazyQuery(GET_CUSTOMERS, {
        variables: {
            term: term.length > 2 ? term.trim() : "",
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
    });

    useEffect(() => {
        if (term && term.length > 2) {
            getCustomers()
        } // eslint-disable-next-line
    }, [term]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTerm(value);
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className={classes.wrap}>
                <RenderTitle element="h2" text="Impersonalisatie" marginSize={"large"} />
                <Typography variant="body1">
                    Hieronder kan je jezelf inloggen onder een specifieke klant. Zoek en selecteer een klant voor de
                    impersonalisatie:
                </Typography>
                <FormControl className={classes.formRoot} variant="filled">
                    <InputLabel htmlFor="filled-adornment-search">{t("impersonate.searchLabel")}</InputLabel>
                    <FilledInput
                        onChange={handleSearch}
                        id="filled-adornment-search"
                        value={term}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        className={classes.searchInput}
                    />
                </FormControl>
                {loading && (
                    <div>
                        <Loader />
                    </div>
                )}
                {!loading && !error && data && data.customers.items.length > 0 && (
                    <React.Fragment>
                        <div ref={customersWrap}>
                            {data.customers.items.map((customer: Customer, key: number) => (
                                <CustomerRow key={key} customer={customer} handlePopUp={setOpenPopUp} handleCustomerId={setCustomerId}/>
                            ))}
                        </div>
                    </React.Fragment>
                )}
                {!loading && !error && data && data.customers.items.length <= 0 && (
                    <Typography variant="body1" color="primary">
                        {t("impersonate.customers.noCustomersFound")}
                    </Typography>
                )}
                {!loading && error && (
                    <Typography variant="body1" color="error">
                        {t("impersonate.error")}
                    </Typography>
                )}
                {openPopUp && <ContactsDialog onClose={() => setOpenPopUp(false)} open={openPopUp} customerId={customerId}/>}
            </div>
        </Container>
    );
};

export default Impersonate;
