import { useTranslation } from "react-i18next";

import { Divider, Grid, Theme, Typography, makeStyles, Box, Link } from "@material-ui/core";

import { DECLINE_RETURN_REASON, RETURN_ORDER_STATUS, RETURN_REASON, USER_PERMISSIONS } from "const";
import { StatusSelector } from "shared";
import { renderMaxTextLength } from "helpers";
import { ReturnOrderLineType } from "types";
import DeclineReasonSelector from "shared/CustomInputs/DeclineReasonSelector";
import useReturnOrder from "../useReturnOrder";
import { useAppSelector } from "hooks";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(4),
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
    },
}));

type Props = {
    returnOrderLine: ReturnOrderLineType;
    orderId?: string;
};

const DialogOrderLine: React.FC<Props> = ({ returnOrderLine, orderId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { order, api } = useReturnOrder();
    const permissions = useAppSelector((state) => state.auth.user.permissions);
    const currentOrderLine = order?.lines?.find((line) => line.contentItemId === returnOrderLine.contentItemId);

    const handleOrderChange = (value: RETURN_REASON | DECLINE_RETURN_REASON | RETURN_ORDER_STATUS, name: string) => {
        const tempOrder = { ...order };
        if (!tempOrder) return;

        const lineIndex = tempOrder?.lines?.findIndex((line) => line.contentItemId === returnOrderLine.contentItemId);
        let lines = tempOrder?.lines ? [...tempOrder.lines] : [];

        if (lineIndex !== undefined && lineIndex >= 0) {
            lines[lineIndex] = {
                ...lines[lineIndex],
                [name]: value,
            };
        }

        tempOrder.lines = lines;

        api.setOrder(tempOrder);
    };

    return (
        <div key={returnOrderLine.contentItemId} className={classes.root}>
            <Box p={3}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={2}>
                        <Typography variant="subtitle1">{returnOrderLine.articleNumber}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1">
                            {returnOrderLine.returnCount || returnOrderLine.quantity}x
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2">
                            {renderMaxTextLength(returnOrderLine.productName, 70, true)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box p={3}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={2}>
                        <Box className={classes.flexBox}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {t("orderLine.orderNumber")}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {orderId}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box className={classes.flexBox}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {t("orderLine.returnReason")}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {t(`returnReason.${returnOrderLine.reason}`)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box className={classes.flexBox}>
                            <Typography variant="subtitle1" color="textSecondary">
                                {t("orderLine.documents")}
                            </Typography>
                            <div className={classes.flexBox}>
                                {returnOrderLine.media?.map((item, idx: number) => {
                                    var splitName = item && item.split("/");
                                    var name = splitName[splitName.length - 1];

                                    return (
                                        <Link key={idx} href={item} target="_blank" rel="noreferrer">
                                            {name}
                                        </Link>
                                    );
                                })}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box p={4}>
                {permissions.includes(USER_PERMISSIONS.Nijburg_ManageOthersReturnOrders) && (
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Grid item xs={5}>
                            <StatusSelector
                                onChange={(val) => handleOrderChange(val, "status")}
                                id="orderStatus"
                                label={t("returnDialog.status")}
                                noPartially={true}
                                value={currentOrderLine?.status || RETURN_ORDER_STATUS.PENDING}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            {currentOrderLine?.status === RETURN_ORDER_STATUS.DECLINED && (
                                <DeclineReasonSelector
                                    label={t("returnDialog.returnReason")}
                                    onChange={(val) => handleOrderChange(val, "reason")}
                                    value={currentOrderLine?.reason || DECLINE_RETURN_REASON.EMPTY}
                                    id="declineReason"
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </div>
    );
};

export default DialogOrderLine;
