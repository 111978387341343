import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

import { useAppDispatch } from "hooks";
import getUserFromToken from "helpers/GetUserFromToken";
import { login, setUser } from "store/slices/AuthSlice";

type Props = {
    path: string;
    component: React.ComponentType<any>;
    exact?: boolean;
};

const PrivateRoute: React.FC<Props> = ({ component: Component, exact, path, ...rest }) => {
    const dispatch = useAppDispatch();
    const cookieToken = Cookies.get("jwt-nijburg");
    let isAuthenticated = false;

    if (cookieToken) {
        isAuthenticated = true;
        try {
            const user = getUserFromToken(cookieToken);
            dispatch(setUser(user));
        } catch (error) {
            console.warn("cannot decoded token");
        }
        dispatch(login());
    }

    return (
        <Route
            path={path}
            exact={exact}
            {...rest}
            render={() => (isAuthenticated ? <Component {...rest} /> : <Redirect to={`/login?redirectPath=${path}`} />)}
        />
    );
};

export default PrivateRoute;
